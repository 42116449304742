import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import ReactSelect from "react-select";
import Moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomMenu from "../../../../../Components/CustomMenu";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/EditSharp";
import {
  Tab,
  List,
  Grid,
  Card,
  Tabs,
  Radio,
  Paper,
  Button,
  Select,
  AppBar,
  Divider,
  ListItem,
  MenuItem,
  Snackbar,
  Checkbox,
  TextField,
  InputLabel,
  RadioGroup,
  Typography,
  CardContent,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  TablePagination,
  IconButton,
  Switch,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import { green } from "@material-ui/core/colors";

import * as userActions from "../../../../../actionCreators/User";
import * as spillActions from "../../../../../actionCreators/Spill";
import * as clientActions from "../../../../../actionCreators/Client";
import * as agencyActions from "../../../../../actionCreators/Agency";
import * as contractorAction from "../../../../../actionCreators/Contractor";
import {
  ROLES,
  RegxList,
  isPESUser,
  arrCompare,
  damageTypes,
  canadaStates,
  filterOption,
  orderStatuses,
  americaStates,
  quantityTypes,
  isCorporateUser,
  statusVisibility,
  canSeeVoidOption,
  getFormattedTime,
  isContractorUser,
  compareContractor,
  orderAlphabatically,
  getContractorOptions,
  canSeeStampsOrAmounts,
  damagedContainerTypes,
  convertToGroupedOptions,
  removeProbPMFromOptions,
  generateContractorLabel,
  orderAlphabaticallyByKey,
  renameAttachmentDuplicates,
  contractorColourStyles as colourStyles,
  renameContractorInvAttachmentsDuplicates,
  COUNTRIES,
} from "../../../../../utils";

import AdminTab from "./AdminTab";
import ContractorAcceptance from "../../../../../Components/EditSpill/ContractorAcceptance";

import { WholeScreenModal, Prompt, TabDetail } from "../../../../../Components";
import {
  ClientOrganizationText as clientOrgText,
  voidSpillText as voidText,
} from "../../../../../utils/language/english";
import { isPESUser_3, sortSpillReservesByDate } from "../../../../../utils/dataProcessors";
import PaginationButtons from "../../../../../Components/PaginationButtons";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Tooltip } from "@material-ui/core";
import CurrencyFieldInput from "../../../../../Components/CurrencyFieldInput";
import { usePrevious } from "../../../../../hooks";
import ClearIcon from '@material-ui/icons/Clear';
import escapeRegExp from '../../../../../utils/helper';
import MaterialClassPopup from './MaterialClassPopup';

const useStylesTablePagination = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: green[500],
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  suggestionsList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    textTransform: "capitalize",
    padding: 0,
  },
  spillPaper: {
    marginTop: 10,
    padding: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  leftAlign: {
    textAlign: "left",
  },
  detailHeading: {
    color: "#295115",
    fontSize: "15px",
    fontWeight: 800,
  },
  header: {
    color: "#b17830",
    fontSize: "20px",
    fontWeight: 800,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  rootLoader: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  linkButton: {
    color: "#295115",
    fontSize: "15px",
    fontWeight: 800,
    textDecoration: "underline",
    cursor: "pointer",
  },
  buttonWrapper: {
    textAlign: "right",
  },
  headerCardBody: {
    padding: "10px",
    backgroundColor: "#e6e6e6d6",
    fontWeight: 600,
    color: "#6aa16c",
    fontSize: "14px",
  },
  closeStatusWarning: {
    display: "flex",
    width: "100%",
    fontSize: "12px",
    fontWeight: 900,
    color: "darkgray",
  },
  cardBody: {
    padding: "10px",
    fontSize: "14px",
  },
  totalArea: {
    fontSize: "15px",
    fontWeight: "800",
  },
  divider: {
    border: "none",
    height: "3px",
    margin: 0,
    flexShrink: 0,
    backgroundColor: "#484848",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  totalCard: {
    backgroundColor: "#E0E0E0",
    padding: "5px 0",
    margin: "15px 0px",
  },
  totalLabel: {
    fontSize: "15px",
    fontWeight: "800",
    padding: "5px 0",
    marginLeft: "5px",
  },
  inActive: {
    fontSize: "13px",
    color: "#616161",
  },
  inActiveHeading: {
    fontSize: "15px",
    color: "#616161",
    fontWeight: 800,
  },
  materialInput: {
    width: "100%",
    height: "35px",
    border: "none",
    borderBottom: "1px solid #949494",
  },
  suggestionsListScroll: {
    maxHeight: "200px",
    overflow: "scroll",
    overflowX: "hidden",
  },
  notesListScroll: {
    maxHeight: "500px",
    overflow: "scroll",
    overflowX: "hidden",
  },
  tabPanel: {
    marginTop: "1rem",
  },
  tabPanelPaper: {
    padding: "1rem 3rem",
  },
  checkBoxMisc: {
    display: "inline",
  },
  leftAlignGrid: {
    textAlign: "left",
    paddingTop: "0",
    paddingBottom: "0",
  },
  paginationWrapper: {
    textAlign: "right",
    margin: "10px 0px",
  },
  notePaginationWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  paginationContainer: {
    display: "flex",
  },
  paginationToolBar: {
    display: "flex",
    alignItems: "center",
  },
  paginationSelect: {
    padding: 0,
  },
  checkBoxAlignment: {
    paddingLeft: "0px",
  },
  watched: {
    textAlign: "right",
  },
  locationTypeLabel: {
    display: "inline",
    marginRight: "5%",
  },
  radioGroupDisplay: {
    display: "inline",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Paper>{children}</Paper>}
    </div>
  );
}

function TablePaginationActions(props) {
  const classes = useStylesTablePagination();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={count && page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={!count || page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditSpill = ({
  users,
  materials,
  materialClass,
  allStatusData,
  currentUser,
  getUsersByOrgId,
  admins,
  getAdmins,
  getOrganizationNames,
  clientOrganizationNames,
  id,
  currentSpill,
  editSpill,
  closeEditSpill,
  getCompleteSpill,
  getAgencies,
  agencies,
  contractorsWithAddress,
  getContractorsWithAddress,
  viewSpill,
  materialsLoading,
  closeSpill,
  getClientAssociatedNotesServices,
  currentSpillServices,
  getMaterial,
  getMaterialClass,
  getStatuses,
  statuses,
  onAccept,
  history,
  success,
  loading,
  updateStatus,
  currentContractorHistory,
  getContractorHistory,
  getSpillAdminAttachmentHistory,
  spillAdminAttachmentHistory,
  getPaginatedNotes,
  paginatedNotes,
  fetchPacketReviewers,
  clearSpillData,
  packetReviewers,
  getSpillAdminAttachmentTypes,
  adminAttachmentTypes,
  facilities,
  getFacility,
  getDisableContaractor,
  disableClientContractors,
  getIcsTerminal,
  getIcsTeamTerminal,
  partialBillingDownload,
  getPartialBillingDownload,
  partialBillingDownloadReset,
  getMaterialClassReset,
  refreshMaterialClassReset,
  resetFetchCertainApiForEditSpill
}) => {
  const classes = useStyles();
  const lastSpillId = useRef();
  const [value, setValue] = useState("");
  const [materialClassValue, setMaterialClassValue] = useState("")
  const [suggestions, setSuggestions] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [spillStatuses, setSpillStatuses] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersChecker, setUsersChecker] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [rejectedContractors, setRejectedContractors] = useState([]);
  const [prevRejectedContractors, setPrevRejectedContractors] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [note, setNote] = useState([]);
  const [spillNotes, setSpillNotes] = useState([]);
  const [spillReserves, setSpillReserves] = useState([]);
  const [states, setStates] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [updateCalled, setUpdateCalled] = useState(false);
  const [missingInvoiceAlert, setMissingInvoiceAlert] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [selectedPacketReviewer, setSelectedPacketReviewer] = useState(null);
  const [packetReviewerToUpdate, setPacketReviewerToUpdate] = useState(null);
  const [fetchedPacketReviewersData, setFetchedPacketReviewersData] = useState({
    data: [],
    loading: false,
    success: false,
    error: null,
  });
  const [selectedFacility, setSelectedFacility] = useState({});
  const [updatedFilesState, setUpdatedFilesState] = useState({
    isNewFilesUploaded: false,
    previousFiles: [],
    newFiles: [],
  });
  const [contractorInv, setContractorInv] = useState(null);

  const [attachmentHistory, setAttachmentHistory] = useState({
    data: [],
    error: null,
    loading: false,
  });
  const [updateClientOrganization, setUpdateClientOrganization] = useState({
    isToBeUpdated: false,
    data: null,
    newJobNo: "",
  });

  const [connection, setConnection] = useState({
    connectionId: null,
    agencyNational: "",
    nationalIncidentNo: "",
    agency: "",
    stateIncidentNo: "",
    agencyId: "",
  });
  const [spill, setSpill] = useState({
    org_id: "",
    facility_id: "",
    user_id: "",
    prev_user_id: "",
    status: "",
    type: "",
    conditions: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: COUNTRIES?.USA,
    contact: "",
    dock_door_number: null,
    released_outside_building: null,
    release_offsite: null,
    shipper_name: null,
    shipper_address: null,
    responsible: "",
    need_5800: "",
    is_waste: "",
    has_msds: "",
    is_hazmat: "",
    un_no: "",
    response_sent: "",
    subrogation: "",
    claim_no: "",
    send_attachment: "",
    rate: "",
    latitude: "",
    longitude: "",
    material: "",
    material_id: "",
    tractor: "",
    trailer: "",
    onsitePocName: "",
    onsitePocPhone: "",
    driverPhone: "",
    driverName: "",
    pro: "",
    mapNeeded: "",
    amountReleased: "",
    actual_amount_released: null,
    actual_quantity_type_released: null,
    quantityTypeReleased: "",
    damagedContainerType: "",
    damageType: "",
    locationType: "",
    drainImpacted: "",
    waterwayImpacted: "",
  });
  const previousSpill = usePrevious(spill);
  const [jobNo, setJobNo] = useState(id);
  const [tabList, setTabList] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [contractorOptions, setContractorOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [totalNotes, setTotalNotes] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [watched, setWatched] = useState(false);
  const [isPageChanged, setIsPageChanged] = useState(false);
  const [isAppendNoteLast, setIsAppendNoteLast] = useState(false);
  const [isSpillEmergency, setIsSpillEmergency] = useState(false);
  const [spillVoid, setSpillVoid] = useState({
    showVoidPrompt: false,
    isVoid: false,
  });
  const [attachmentTypes, setAttachmentTypes] = useState({
    general: [],
    completed: [],
    restricted: [],
  });
  const [facilityOption, setFacilityOption] = React.useState([]);
  const [response, setResponse] = useState(true);
  const [selectedTerminalId, setSelectedTerminalId] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [showTeamClientDropdown, setTeamClientDropdown] = useState(false);
  const [partialBillingDownloadData, setPartialBillingDownloadData] = useState({})
  const [partialBillingDownloadSuccessFlag, setPartialBillingDownloadSuccessFlag] = useState(false); //only reset the partial billing state when call from edit spill and got success
  const [totalArea, setTotalArea] = useState({});
  const [materialClassData, setMaterialClassData] = useState([]);
  const [filteredMaterialClassData, setFilteredMaterialClassData] = useState([])
  const [selectedTerminalTeam, setSelectedTerminalTeam] = useState(null)
  const [terminalTeams, setTerminalTeams] = useState([])
  const [ filteredTerminalTeams, setFilteredTerminalTeams] = useState([]);
  const [terminalTeamsValue, setTerminalTeamsValue] = useState("");
  const [initialUpdate, setInitialUpdate] = useState(true)
  const [showMaterialClassEditPopup, setShowMaterialClassEditPopup] = useState(false)
  
  let currentURL = window.location.href;

  const updateInfo = () => {
    getOrganizationNames();
    getAdmins({ filter: "none" });
    getStatuses();
    getCompleteSpill({ jobNo, ...{ mode: "edit" } });
    getAgencies();
    getContractorsWithAddress({ withDonotUse: false });
    getSpillAdminAttachmentTypes();
  };

  // set the data of the partial billing in a state
  useEffect(() => {
    setPartialBillingDownloadData(partialBillingDownload)
  }, [partialBillingDownload])

  // fetch the partial billing data when edit spill component mounts
  useEffect(() => {
    if(currentSpill?.data?.spill?.id) {
      getPartialBillingDownload({spill_id:currentSpill?.data?.spill?.id }) 
      setPartialBillingDownloadSuccessFlag(true)
    }
  }, [currentSpill?.data?.spill?.id])

  useEffect(() => {
    // if success then reset the partialBillingDownload Succes state only when api is called from edit spill component
    if(partialBillingDownload?.success && partialBillingDownloadSuccessFlag) {
      partialBillingDownloadReset()
      setPartialBillingDownloadSuccessFlag(false)
    }
  }, [partialBillingDownload?.success])

  useEffect(() => {
    updateInfo();
    getMaterialClass();
    fetchPacketReviewers();
  }, []);

  // set the material class data
  useEffect(() => {
    if(materialClass?.success) {
      setMaterialClassData(materialClass?.data);
      setFilteredMaterialClassData(materialClass?.data)
      refreshMaterialClassReset()
    }
    getMaterialClassReset()
  }, [materialClass?.success, materialClass?.error, currentSpill?.refreshMaterialClass])

  useEffect(() => {
    if(currentSpill?.refreshMaterialClass) {
      getMaterialClass()
      refreshMaterialClassReset()
    }
  }, [currentSpill?.refreshMaterialClass])

  
  useEffect(() => {
    const restrictedTypes = adminAttachmentTypes?.data?.filter(
      (attachment) => attachment?.type === "Restricted"
    );

    const generalTypes = adminAttachmentTypes?.data?.filter(
      (attachment) => attachment?.type === "General"
    );

    const completedTypes = adminAttachmentTypes?.data?.filter(
      (attachment) => attachment?.type === "Completed"
    );

    setAttachmentTypes({
      general: generalTypes,
      completed: completedTypes,
      restricted: restrictedTypes,
    });
  }, [adminAttachmentTypes]);

  useEffect(() => {
    const newContractors = getContractorOptions(contractorsWithAddress.data);
    setContractorOptions(convertToGroupedOptions(newContractors));
  }, [contractorsWithAddress]);

  /* useEffect to fetch certain APIs once when edit spill is successful(call all the API which needs to be called once after edit spill success) */
  useEffect(() => {
    if(currentSpill?.fetchCertainApiOnce) {
      if (
        currentSpill?.data?.spill?.status === "Open: Documentation In Review" ||
        currentSpill?.data?.spill?.status ===
        "Open: Documentation Sent Back to Contractor for Revision"
      ) {
        fetchPacketReviewers();
      }
      // reset to false after all the api calls are done
      resetFetchCertainApiForEditSpill()
    }
  }, [currentSpill?.fetchCertainApiOnce])

  document.title = currentSpill?.data?.spill?.job_no || "fetching...";
  useEffect(() => {
    if (currentSpill?.data) {
      let spillData = currentSpill?.data?.spill;
      setIsSpillEmergency(currentSpill?.data?.spill?.is_emergency);
      // setValue(
      //   spillData?.spill_material ? spillData?.spill_material?.name : ""
      // );

      if (currentSpill?.data?.spill?.assigned_packet_reviewer) {
        const packetReviewerString = `${currentSpill?.data?.spill?.assigned_packet_reviewer?.updatedPacketReviewerData?.packet_reviewer_user_full_name} (${currentSpill?.data?.spill?.assigned_packet_reviewer?.count})`;

        setSelectedPacketReviewer(packetReviewerString);
      }

      setWatched(spillData.watched);
      setSpill((val) => {
        return {
          ...val,
          user_id: spillData.user_id || "",
          org_id: spillData.org_id || "",
          facility_id: spillData?.facility_id || "",
          status: spillData.status || "",
          type: spillData.type || "",
          conditions: spillData.conditions || "",
          address: spillData.address || "",
          city: spillData.city || "",
          state: spillData.state || "",
          zip_code: spillData.zip_code || "",
          country: spillData.country || COUNTRIES?.USA,
          contact: spillData.contact || "",
          responsible: spillData.responsible || "",
          material: spillData?.material || "",
          material_id: spillData?.material_id || "",
          need_5800: spillData.need_5800 !== "" ? spillData.need_5800 : "",
          is_waste: spillData.is_waste !== "" ? spillData.is_waste : "",
          has_msds: spillData.has_msds !== "" ? spillData.has_msds : "",
          is_hazmat: spillData.is_hazmat !== "" ? spillData.is_hazmat : "",
          un_no: spillData?.un_no || "",
          response_sent:
            spillData.response_sent !== "" ? spillData.response_sent : "",
          subrogation:
            spillData.subrogation !== "" ? spillData.subrogation : "",
          claim_no: spillData.claim_no || "",
          send_attachment: spillData.send_attachment || "",
          rate: spillData.rate || spillData?.client_organization?.rate,

          latitude: spillData.latitude || "",
          longitude: spillData.longitude || "",
          tractor: spillData.tractor || "",
          trailer: spillData.trailer || "",
          onsitePocName: spillData.onsite_poc_name || "",
          onsitePocPhone: spillData.onsite_poc_phone || "",
          driverPhone: spillData.driver_phone || "",
          driverName: spillData.driver_name || "",
          pro: spillData.pro || "",
          mapNeeded: spillData.map_needed !== 0 ? spillData.map_needed : "",
          amountReleased: spillData?.amount_released || "",
          quantityTypeReleased: spillData.quantity_type_released || "",
          damagedContainerType: spillData.damaged_container_type || "",
          damageType: spillData.damage_type || "",
          locationType: spillData.location_type || "",
          drainImpacted: spillData.drain_impacted || "",
          waterwayImpacted: spillData.waterway_impacted || "",
          is_emergency: spillData.is_emergency || false,
          dock_door_number: spillData.dock_door_number || "",
          shipper_name: spillData.shipper_name || "",
          shipper_address: spillData.shipper_address || "",
          released_outside_building: spillData.released_outside_building !== "" ? spillData.released_outside_building : "",
          release_offsite: spillData.release_offsite !== "" ? spillData.release_offsite : "",
          actual_amount_released: spillData.actual_amount_released || "",
          actual_quantity_type_released: spillData.actual_quantity_type_released || "",
        };
      });
      if (spillData?.material) {
        setValue(spillData?.material);
      }
      if(spillData?.material_class) {
        setMaterialClassValue(spillData?.material_class)
      }
      getStateName(spillData?.country ?? COUNTRIES?.USA);
    }
  }, [currentSpill, spillVoid.isVoid]);

  useEffect(() => {
    if (currentSpill?.data) {
      const { reserves, assigned_packet_reviewer } = currentSpill?.data?.spill;
      if (reserves?.length > 0) {
        const sortedReserves = sortSpillReservesByDate(reserves);
        setSpillReserves(sortedReserves);
      }

      if (assigned_packet_reviewer) {
        const packetReviewerString = `${assigned_packet_reviewer?.updatedPacketReviewerData?.packet_reviewer_user_full_name} (${assigned_packet_reviewer?.count})`;

        setSelectedPacketReviewer(packetReviewerString);
      }

      // if (currentSpill?.data?.spill?.id && !spillVoid?.isVoid) {
      //   getContractorHistory({ spillId: currentSpill?.data?.spill?.id });
      // }

      if (currentSpill?.data?.connection) {
        setConnection((val) => {
          return {
            ...val,
            connectionId: currentSpill?.data?.connection?.id || null,
            agencyNational:
              currentSpill?.data?.connection?.agency_national || "",
            nationalIncidentNo:
              currentSpill?.data?.connection?.incident_no || "",
            agency: currentSpill?.data?.connection?.agency || "",
            stateIncidentNo:
              currentSpill?.data?.connection?.state_incident_no || "",
            agencyId: currentSpill?.data?.connection?.agency_id || "",
          };
        });
      } else {
        setConnection({
          connectionId: null,
          agencyNational: "",
          nationalIncidentNo: "",
          agency: "",
          stateIncidentNo: "",
          agencyId: "",
        });
      }

      if (currentSpill?.data?.spill?.spill_notes_data?.count > 0) {
        setTotalNotes(currentSpill?.data?.spill?.spill_notes_data?.count);
      }

      if (currentSpill?.data?.spill?.recipients) {
        let users = currentSpill.data.spill.recipients.map((recipient) => {
          const user = {
            value: recipient?.user_id,
            label: `${recipient?.user?.full_name} (${recipient?.user?.client_organization?.name})`,
            isDefault: recipient?.user?.is_default,
            deletedAt: recipient?.deleted_at,
          };
          return user;
        });

        users = users?.filter((user) => user?.deletedAt === null);

        setSelectedUsers(users);
        setUsersChecker(users);
      }
      if (currentSpillServices?.data) {
        const data = currentSpillServices.data;

        setServices(data);
      }
      if (statuses) {
        const data = statuses;

        let orderSpillStatuses = orderStatuses(data);

        setSpillStatuses(orderSpillStatuses);
      }
      if (materials) {
        const data = materials;

        const inputValue = (value || "").toString().trim().toLowerCase();
        const inputLength = inputValue?.length;

        const filteredMaterials =
          inputLength === 0
            ? []
            : data.filter(
              (material) =>
                material?.name?.toLowerCase()?.slice(0, inputLength) ===
                inputValue
            );

        setSuggestions(filteredMaterials);
      }
      if(materialClass?.data) {
        setMaterialClassData(materialClass?.data);
        setFilteredMaterialClassData(materialClass?.data);
      }
      if (currentSpill.data?.spill?.status) {
        setSpill((val) => {
          return {
            ...val,
            status: currentSpill.data.spill.status || "",
          };
        });
      }
    }
  }, [
    spillVoid?.isVoid,
    currentSpill.data,
    currentSpill.data?.admin,
    currentSpill,
    currentSpillServices.data,
    materials,
    allStatusData,
    statuses,
    value,
    materialClass?.data
  ]);

  // set the contractors and admins only when they get change not when value changes of material
  useEffect(() => {
    if (currentSpill?.data?.admin?.length) {
      let tempAdmins = [];
      currentSpill?.data?.admin.forEach((item, adminIndex) => {
        tempAdmins.push({
          adminId: item.id || null,
          contractorId: item.contractor_id || null,
          contractorAddressId: item.contractor_address_id || null,
          info: item.info || "",
          pix: item.pix
            ? Moment(new Date(item.pix)).format("MM-DD-YYYY")
            : null,
          spillSummary: item.spill_summary
            ? Moment(new Date(item.spill_summary)).format("MM-DD-YYYY")
            : null,
          contractorInv: item.contractor_inv
            ? Moment(new Date(item.contractor_inv)).format("MM-DD-YYYY")
            : null,
          wasteDoc: item.waste_doc
            ? Moment(new Date(item.waste_doc)).format("MM-DD-YYYY")
            : null,
          contractorInvoice: item.contractor_invoice || "",
          invNo: item.inv_no || "",
          finalContractorInv: item.final_contractor_invoice || "",
          savings: item.savings || "",
          pesInvNo: item.pes_inv_no || null,
          pesInvAmount: item.pes_inv_amount || null,
          pesPaid: item.pes_paid
            ? Moment(new Date(item.pes_paid)).format("MM-DD-YYYY")
            : null,
          contractorPaid: item.contractor_paid
            ? Moment(new Date(item.contractor_paid)).format("MM-DD-YYYY")
            : null,
          transToCt: item.trans_to_ct
            ? Moment(new Date(item.trans_to_ct)).format("MM-DD-YYYY")
            : null,
          payBy: item.pay_by
            ? Moment(new Date(item.pay_by)).format("MM-DD-YYYY")
            : null,
          responseTime: item.response_time || "00:00",
          isRemoved: item.is_removed,
          isMain: item.is_main,
          attachments: item.spill_attachments,
          isComplete: item.is_complete,
          isInactive: item?.is_removed,
          status: item.status,
          rejectReason: item.reject_reason,
          isNotRequired: item.is_not_required,
          isNotRequiredCheck: item.is_not_required,
          attachments_with_expiry: item?.attachments_with_expiry,
          progressive_billing:item?.progressive_billing, 
          agency_report_download:item?.invoice_report_download,
          invoice_report_download:item?.invoice_report_download,  
        });
      });
      if (spillVoid?.isVoid) {
        tempAdmins = [];
      }
      setAdmin(tempAdmins);
    }
    // setSelectedContractor empty if the Material value changes
    if (currentSpill?.data?.spillContractors) {
      setSelectedContractors(
        currentSpill.data.spillContractors.map((x) => ({
          ...x,
          label: generateContractorLabel(x),
        }))
      );
    }
  }, [currentSpill?.data])

  useEffect(() => {
    if (facilities?.success && Array.isArray(facilities?.data)) {
      setFacilityOption([]);
      setSelectedFacility({});
      const facilityOptions = facilities?.data.map((facility) => {
        const value = {
          value: facility.id,
          label: facility.name
        };
        const allOptions = { ...value };
        return allOptions;
      });
      setFacilityOption(facilityOptions);
      if (currentSpill?.data?.spill?.facility) {
        setSelectedFacility({
          value: currentSpill?.data?.spill?.facility?.id || null,
          label: currentSpill?.data?.spill?.facility?.name || null
        })
      }
    }
  }, [facilities?.data, facilities?.success]);

  useEffect(() => {
    if (paginatedNotes?.data?.length > 0) {
      setSpillNotes(paginatedNotes?.data);
      if (paginatedNotes?.totalArea?.length) {
        const total_permeable_area = parseInt(paginatedNotes?.totalArea[0]?.total_permeable_area, 10 ) || 0;
        const total_impermeable_area = parseInt(paginatedNotes?.totalArea[0]?.total_impermeable_area, 10) || 0;
        setTotalArea({
          total_permeable_area,
          total_impermeable_area,
          total: total_permeable_area + total_impermeable_area
        });
      }
    }
  }, [paginatedNotes]);

  useEffect(() => {
    const rejectedPreviously = currentContractorHistory;
    setPrevRejectedContractors(rejectedPreviously.data || []);
  }, [currentContractorHistory.loading]);

  useEffect(() => {
    if (currentUser?.data?.role) {
      let list = [];
      let role = currentUser?.data?.role;
      if (!isContractorUser(role?.role)) {
        if (role.permission.edit_own_notes) {
          list.push({ label: "Notes & Attachments", index: 4 });
          setTabValue(4);
        } else {
          list = [
            { label: "Overview", index: 0 },
            { label: "Connections", index: 1 },
            { label: "Miscellany", index: 2 },
            { label: "Reserves", index: 5 },
            { label: "Notes & Attachments", index: 4 },
          ];
        }
        if (isPESUser(role?.role))
          list.push(
            { label: "Admin", index: 3 },
            isPESUser_3(role?.role) && { label: "Information", index: 6 }
          );
      } else {
        list.push({ label: "Admin", index: 3 });
        setTabValue(3);
      }
      setTabList(list);
    }
  }, [currentUser]);

  useEffect(() => {
    if (packetReviewers?.error) {
      setSnackBarSeverity("error");
      setSnackBarMessage(
        "There was some problem while fetching packet reviewers. Please try again later"
      );
      setSnackBarOpen(true);
    }
    if (packetReviewers?.data?.length > 0) {
      setFetchedPacketReviewersData({
        ...fetchedPacketReviewersData,
        data: packetReviewers?.data,
        loading: packetReviewers?.loading,
        success: packetReviewers?.success,
      });
    }
  }, [packetReviewers]);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const voidPromptHandler = (displayPrompt) => {
    setSpillVoid({ ...spillVoid, showVoidPrompt: displayPrompt });
  };

  const clearSpillDataHandler = () => {
    lastSpillId.current = null;
    setSpillVoid({ showVoidPrompt: false, isVoid: true });
    clearSpillData({
      spillId: currentSpill.data?.spill?.id,
      connectionId: connection?.connectionId,
      userId: spill?.user_id,
      legacyId: currentSpill.data?.spill?.legacy_id,
      jobNo: currentSpill.data?.spill?.job_no,
    });
  };

  useEffect(() => {
    if (!currentSpill?.data?.spill?.id) {
      return;
    }
    if (lastSpillId.current !== currentSpill?.data?.spill?.id) {
      getPaginatedNotes({
        spillId: currentSpill?.data?.spill?.id,
      });
      lastSpillId.current = currentSpill?.data?.spill?.id;
    }
  }, [currentSpill?.data]);

  // Montitor the spill status and update state accordingly
  useEffect(() => {
    if(!["Open: Documentation In Review", "Open: Documentation Sent Back to Contractor for Revision"]?.includes(currentSpill?.data?.spill?.status)) {
      setSelectedPacketReviewer(null);
      setPacketReviewerToUpdate(null)
    }
  }, [currentSpill?.data?.spill?.status])

  useEffect(() => {
    if (currentSpill?.success) {
      // empty the admin state when there currentSpill admin gets empty
      !currentSpill?.data?.admin?.length && setAdmin([])
      !currentSpill?.data?.spill?.material && setValue('')
      !currentSpill?.data?.spill?.material_class && setMaterialClassValue('')
      !currentSpill?.data?.spill?.reserves?.length && setSpillReserves([]);

      let spillData = currentSpill?.data?.spill;
      setSpillVoid({ showVoidPrompt: false, isVoid: false });

      if (!spillVoid.isVoid) {
        getUsersByOrgId({ id: spillData?.org_id });
        getClientAssociatedNotesServices({
          org_id: spillData?.org_id,
          spill_id: spillData?.id,
        });
      }

      if (currentSpill?.data?.spill?.id) {
        getContractorHistory({ spillId: currentSpill.data?.spill?.id });
      }
    }
  }, [currentSpill?.success]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    if (!isAllValidated()) return;
    let recipients = [];
    const attachments = [];
    const fileData = [];
    const noteFileData = [];
    lastSpillId.current = null;
    recipients = selectedUsers?.length > 0 ? selectedUsers : [];
    const jobNoDto = currentSpill.data?.spill.job_no.split("-");
    jobNoDto[1] = clientOrganizationNames?.data?.filter(
      (clientOrg) => clientOrg?.id === spill?.org_id
    )[0]?.code;
    const updatedJobNo = jobNoDto?.join("-");
    setJobNo(updatedJobNo);

    const selectedFacilityData = facilities?.data.find(
      (facility) => facility?.id === selectedFacility?.value
    );
  
    const completeSpill = {
      ...spill,
      prev_user_id: currentSpill.data.spill.user_id,
      material: { name: value || null },
      material_class: materialClassValue && materialClassValue?.trim() ? materialClassValue?.trim() : null,
      id: currentSpill.data?.spill?.id,
      job_no: updatedJobNo,
      connectionData: connection,
      spillContractors: selectedContractors,
      adminData: admin,
      recipients,
      note,
      is_emergency: isSpillEmergency,
      watched: watched,
      facility_id: selectedFacility ? selectedFacility?.value : null,
      team_id: selectedTeamId || null,
      terminal_id: selectedTerminalId || null,
      facility_address: selectedFacilityData
      ? {
          address: selectedFacilityData.address,
          city: selectedFacilityData.city,
          state: selectedFacilityData.state,
          country: selectedFacilityData.country,
          zip_code: selectedFacilityData.zip_code,
        }
      : null,
      terminal_addresss: selectedTerminalTeam
      ? {
          address: selectedTerminalTeam?.address,
          city: selectedTerminalTeam?.city,
          state: selectedTerminalTeam?.state,
          zip_code: selectedTerminalTeam?.zip,
          country: selectedTerminalTeam?.country,
        }
      : null,
    };

    note.forEach((item, index) => {
      item?.attachments &&
        attachments.push({ files: item?.attachments, index: index });
    });
    if (attachments.length > 0) {
      attachments.forEach((attachment, i) => {
        attachment.files.forEach((file) => {
          formData.append("file", file, `note#${file?.name}`);
          noteFileData.push({
            noteIndex: attachment.index,
          });
        });
      });
    }

    if (selectedFiles.length) {
      for (let i in selectedFiles) {
        for (let j in selectedFiles[i]) {
          formData.append(
            "file",
            selectedFiles[i][j].file,
            `admin#${selectedFiles[i][j].file?.name}`
          );
          fileData.push({
            adminIndex: i,
            type: selectedFiles[i][j].type,
            size: selectedFiles[i][j].file.size,
            inv_amount: selectedFiles[i][j].inv_amount,
            isFileRequested: selectedFiles[i][j].isFileRequested,
            requestId: selectedFiles[i][j].requestId,
          });
        }
      }
    }

    if (updatedFilesState?.newFiles?.length) {
      for (let i in updatedFilesState?.newFiles) {
        formData?.append(
          "file",
          new Blob([updatedFilesState?.newFiles[i]?.file], { type: updatedFilesState?.newFiles[i]?.file?.type }),
          `admin-replace#${updatedFilesState?.newFiles[i]?.file?.name}`
        );
      }
    }

    formData.append("fileData", JSON.stringify(fileData));
    formData.append("noteFileData", JSON.stringify(noteFileData));
    formData.append("completeSpill", JSON.stringify(completeSpill));
    formData.append("rejectedContractors", JSON.stringify(rejectedContractors));
    formData.append(
      "packetReviewerToUpdate",
      JSON.stringify(packetReviewerToUpdate)
    );

    updatedFilesState?.isNewFilesUploaded &&
      formData.append("updatedFilesState", JSON.stringify(updatedFilesState));

    if (jobNo !== updatedJobNo) {
      setUpdateClientOrganization({
        data: formData,
        isToBeUpdated: true,
        newJobNo: updatedJobNo,
      });
    } else {
      setUpdateCalled(true);
      if(selectedTerminalTeam?.teamId && selectedTerminalTeam?.terminalId) {
        getIcsTeamTerminal({ team_id: selectedTerminalTeam?.teamId, terminal_id: selectedTerminalTeam?.terminalId });
      } 
      editSpill(formData);
    }

    currentUser.data?.role.role === ROLES.PROB_PM &&
      !onAccept &&
      history.push("/dashboard/actions");

    onAccept && onAccept();

    setNote([]);
    setUpdatedFilesState({
      isNewFilesUploaded: false,
      previousFiles: [],
      newFiles: [],
    });
    setContractorInv(null);
  };

  const updateSpillHandler = () => {
    editSpill(updateClientOrganization.data);
    setUpdateCalled(true);
    setUpdateClientOrganization({
      data: null,
      isToBeUpdated: false,
      newJobNo: "",
    });
    let urlToUpdate = currentURL.split("/");
    urlToUpdate[urlToUpdate.length - 1] = updateClientOrganization.newJobNo;
    history.push(urlToUpdate[urlToUpdate.length - 1]);
  };

  useEffect(() => {
    if (!loading && success && updateCalled) {
      setSnackBarSeverity("success");
      setSnackBarMessage("Spill has been updated successfully.");
      setSelectedFiles([]);
      setRejectedContractors([]);
      // updateInfo();
      setSpillNotes([]);
      setSnackBarOpen(true);
      setUpdateCalled(false);
    } else if (!success && !loading && updateCalled) {
      setSnackBarSeverity("error");
      setSnackBarMessage(
        "There was some problem updating the spill.Please try again later"
      );
      setSnackBarOpen(true);
      setUpdateCalled(false);
    }
  }, [loading, success, updateCalled]);

  const [userOptions, setUserOptions] = useState(null);
  const [isClientOrgUpdated, setIsClientOrgUpdated] = useState(false);
  const [isUsersToEmailUpdated, setIsUsersToEmailUpdated] = useState(false);

  useEffect(() => {
    let defaultUsersList = [];
    if (users && Array.isArray(users.data)) {
      let userOptions = users.data
        .map((user) => {
          const value = {
            value: user.id,
            label: user.full_name,
            active: user.active,
            isDefault: user.is_default,
          };
          const allOptions = { ...value };

          return allOptions;
        })
        .filter((user) => user.active);

      defaultUsersList = userOptions?.filter((user) => user.isDefault === true);
      setUserOptions(userOptions);
      if (isUsersToEmailUpdated) {
        isClientOrgUpdated && setSelectedUsers(defaultUsersList);
      }
    }
  }, [users, isClientOrgUpdated, isUsersToEmailUpdated]);

  const handleUpdateUsersToEmail = (selectedOption) => {
    setSelectedUsers(selectedOption);
    setIsUsersToEmailUpdated(true);
    setIsClientOrgUpdated(false);
  };

  const [selectedOrganization, setSelectedOrgName] = useState("");
  const dispatch = useDispatch();
  const TeamData = useSelector((state) => state?.client?.clientTerminal?.data);
  const TeamTerminalData = useSelector((state) => state?.client?.clientTeamTerminal?.data);
  const selectedOrganizationis = clientOrganizationNames?.data?.find((org) => org?.id === spill?.org_id);
  const isIcsClient = selectedOrganizationis?.is_ics_client;

  const handleTerminalTeamSelect = (terminalTeam) => {

    setTerminalTeamsValue(terminalTeam?.terminalName || '');
    if(!terminalTeam?.terminalName) {
      setFilteredTerminalTeams(terminalTeams);
    }
    if(terminalTeam) {
      setSpill((val) => ({
        ...val,
        city: terminalTeam?.city || "",
        state: terminalTeam?.state || "",
        zip_code: terminalTeam?.zip || "",
        address: terminalTeam?.address || "",
        country: terminalTeam?.country ?? COUNTRIES?.USA
      }));
      getStateName(terminalTeam?.country ?? COUNTRIES?.USA);
    }
    setSelectedTerminalTeam(terminalTeam);
    setSelectedTeamId(terminalTeam?.teamId);
    setSelectedTerminalId(terminalTeam?.terminalId);
  }

  const dataRetrievedRef = useRef(false);

  // populate the terminal team with updated terminal team
  useEffect(() => {
    if (Array.isArray(terminalTeams) && selectedTerminalId && initialUpdate) {
      let terminalTeam = terminalTeams?.find((terminal) => terminal?.terminalId === selectedTerminalId);
      if (terminalTeam) {
        setInitialUpdate(false);
        setSelectedTerminalTeam(terminalTeam);
        getIcsTeamTerminal({ team_id: terminalTeam?.teamId, terminal_id: terminalTeam?.terminalId });
      }
    }
  }, [terminalTeams, selectedTerminalId, initialUpdate]);

  useEffect(() => {
    const ClientName = currentSpill?.data?.spill?.client_organization?.name;
    const teamId = currentSpill?.data?.spill?.team_id;
    const terminalId = currentSpill?.data?.spill?.terminal_id;
    setSelectedTeamId(teamId)
    setSelectedTerminalId(terminalId)

    // condition
    if (showTeamDropdown == true && ClientName && !teamId) {
      if (!dataRetrievedRef.current) {
        getIcsTerminal({ client_name: ClientName, terminal_list: true });
        dataRetrievedRef.current = true;
      }
    }
    // when response is selected as no then this cindition would become true and set the team and terminal of the client as before and for only for the ics client 
    else if (showTeamDropdown && ClientName && (teamId || terminalId) && TeamData) {
      if (!dataRetrievedRef.current) {
        getIcsTerminal({ client_name: ClientName, terminal_list: true });
        dataRetrievedRef.current = true;
      }
    }
    // if response is no from the promp to update then reset the spill
    if (currentSpill?.data?.spill && !response) {
      let spillData = currentSpill?.data?.spill;
      setSpill((val) => {
        return {
          ...val,
          user_id: spillData.user_id || "",
          org_id: spillData.org_id || "",
          facility_id: spillData?.facility_id || "",
        };
      });
      // reset job number as it was and also set the response to true
      setResponse(true)
      setJobNo(id)
    }
  }, [currentSpill, TeamData, response]); //adding response as a dependdency so when client which has team and terminal selected and then change the client and when on update if selecets no for changes we should keep team and terminal of the client selected 


  useEffect(() => {
    if(Array.isArray(TeamData)) {
      setTerminalTeams(TeamData);
      setFilteredTerminalTeams(TeamData);
    }
  }, [TeamData]);

  const getStateName = (country) => {
      if (country === COUNTRIES?.USA) {
        setStates(americaStates);
      } else if (country === COUNTRIES?.CANADA) {
        setStates(canadaStates);
      }
  }

  const handleSpillChange = (key) => (event) => {
    if (key === "country") {
      getStateName(event?.target?.value);
    }
    if (key === "org_id") {
      setSelectedTerminalTeam(null)
      setSelectedUsers([]);
      getUsersByOrgId({ id: event?.target?.value });
      setIsClientOrgUpdated(true);
      setIsUsersToEmailUpdated(true);
      getFacility({ org_id: event?.target?.value });
      const selectedOrganization = clientOrganizationNames.data.find((org) => org.id === event.target.value);
      setSelectedOrgName(selectedOrganization ? selectedOrganization.name : "");
      if (selectedOrganization.name) {
        setSelectedTeamId(null);
        setSelectedTerminalId(null);
      }
      if (selectedOrganization && selectedOrganization?.is_ics_client === true) {
        setTeamClientDropdown(true);
        dispatch(getIcsTerminal({ client_name: selectedOrganization ? selectedOrganization?.name : "", terminal_list: true }));

      } else {
        setTeamClientDropdown(false);
      }

    } else if (key === "status") {
      updateStatus({
        spill_id: currentSpill.data.spill.id,
        status: event,
        job_no: currentSpill?.data?.spill?.job_no,
      });
    }

    setSpill((val) => {
      const newSpill = {
        ...val,
        [key]: key === "status" ? event : event.target.value,
      };
      checkValidation(key, newSpill);
      return newSpill;
    });
  };

  const handleMiscChange = (key) => (event) => {
    setSpill((val) => {
      const newSpill = { ...val, [key]: event.target.checked };
      checkValidation(key, newSpill);
      return newSpill;
    });
  };

  const checkLatAndLong = (key) => (event) => {
    if (currentSpill?.data?.spill) {
      return false;
    } else {
      return false;
    }
  };

  const handleConnectionChange = (key) => (event) => {
    setConnection((val) => ({ ...val, [key]: event.target.value }));
  };

  const isAllValidated = () => {

    if(!materialClassValue && isPESUser_3(currentUser?.data?.role?.role)) {
      setSnackBarSeverity("error");
      setSnackBarMessage("Material class is required");
      setSnackBarOpen(true);
      setTabValue(2)
      window.scroll({
        top: 400,
        left: 0,
        behavior: 'smooth'
      });
      return false;
    }

    if (spill?.locationType === "Client Facility" && !selectedTerminalTeam && isIcsClient) {
      const existOverviewOnEdit = tabList.some(tab => tab.label === 'Overview');
      if(existOverviewOnEdit) {
        setSnackBarSeverity("error");
        setSnackBarMessage("Please select terminal.");
        setSnackBarOpen(true);
        return false;
      }
    }
    for (let [key, val] of Object.entries(validationErrors)) {
      if (val?.msg) {
        setValidationErrors({
          ...validationErrors,
          [key]: {
            ...validationErrors[key],
            showError: true,
          },
        });

        return false;
      }
    }
    return true;
  };

  const checkValidation = (key, newSpill) => {
    if (
      [
        "need_5800",
        "is_hazmat",
        "un_no",
        "latitude",
        "longitude",
        "rate",
        "amountReleased",
      ].includes(key)
    ) {
      if ((newSpill.need_5800 || newSpill.is_hazmat) && !newSpill.un_no) {
        setValidationErrors({
          ...validationErrors,
          un_no: { msg: "Required" },
        });
      } else {
        setValidationErrors({ ...validationErrors, un_no: null });
      }

      if (newSpill.un_no) {
        (RegxList.HAVE_NON_DIGIT.test(newSpill.un_no) ||
          newSpill.un_no.length !== 4) &&
          setValidationErrors({
            ...validationErrors,
            un_no: {
              msg: "This Field Only Recognizes Numeric Values. Please Enter a Valid 4-Digit UN/NA # For The Product",
            },
          });
        if (
          (key === "un_no" || key === "is_hazmat") &&
          RegxList.NUMERIC_FOUR_DIGIT.test(newSpill.un_no)
        ) {
          key === "is_hazmat" && setSpill({ ...newSpill, is_hazmat: true });
          setSpill({ ...newSpill, need_5800: true, is_hazmat: true });
        }
      }

      if (newSpill.latitude && key === "latitude") {
        if (
          key === "latitude" &&
          newSpill.latitude.includes(".") &&
          RegxList.NUMERIC_DECIMAL_DIGIT.test(newSpill.latitude)
        ) {
          setSpill({ ...newSpill, latitude: newSpill.latitude });
          setValidationErrors({
            ...validationErrors,
            latitude: null,
          });
        } else if (
          (key === "latitude" &&
            !RegxList.NUMERIC_DECIMAL_DIGIT.test(newSpill.latitude)) ||
          !newSpill.latitude.includes(".")
        ) {
          setValidationErrors({
            ...validationErrors,
            latitude: {
              msg: "This Field Only Recognizes Decimal values. Please Enter a Valid Decimal Latitude",
            },
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            latitude: null,
          });
        }
      }

      if (newSpill.longitude && key === "longitude") {
        if (
          key === "longitude" &&
          newSpill.longitude.includes(".") &&
          RegxList.NUMERIC_DECIMAL_DIGIT.test(newSpill.longitude)
        ) {
          setSpill({ ...newSpill, longitude: newSpill.longitude });
          setValidationErrors({
            ...validationErrors,
            longitude: null,
          });
        } else if (
          (key === "longitude" &&
            !RegxList.NUMERIC_DECIMAL_DIGIT.test(newSpill.longitude)) ||
          !newSpill.longitude.includes(".")
        ) {
          setValidationErrors({
            ...validationErrors,
            longitude: {
              msg: "This Field Only Recognizes Decimal values. Please Enter a Valid Decimal Longitude",
            },
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            longitude: null,
          });
        }
      }

      if (newSpill.rate && key === "rate") {
        if (
          key === "rate" &&
          newSpill.rate.includes(".") &&
          RegxList.NUMERIC_DECIMAL_DIGIT_RATE.test(newSpill.rate)
        ) {
          setSpill({ ...newSpill, rate: newSpill.rate });
          setValidationErrors({
            ...validationErrors,
            rate: null,
          });
        } else if (
          (key === "rate" &&
            !RegxList.NUMERIC_DECIMAL_DIGIT_RATE.test(newSpill.rate)) ||
          !newSpill.rate.includes(".")
        ) {
          setValidationErrors({
            ...validationErrors,
            rate: {
              msg: "This Field Only Recognizes Decimal values. Please Enter a Valid Decimal Rate",
            },
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            rate: null,
          });
        }
      }

      if (newSpill.amountReleased && key === "amountReleased") {
        const amountReleasedIsInt = isInt(newSpill.amountReleased);
        if (amountReleasedIsInt === undefined) {
          setValidationErrors({
            ...validationErrors,
            amountReleased: {
              msg: "Please enter a valid amount",
            },
          });
          return;
        }
        const regexToTest = amountReleasedIsInt
          ? RegxList.NUMERIC_NINE_DIGIT
          : RegxList.NUMERIC_NINE_DIGIT_BEFORE_AND_AFTER_POINT;
        if (
          key === "amountReleased" &&
          regexToTest.test(newSpill.amountReleased)
        ) {
          setSpill({ ...newSpill, amountReleased: newSpill.amountReleased });
          setValidationErrors({
            ...validationErrors,
            amountReleased: null,
          });
        } else if (
          key === "amountReleased" &&
          !regexToTest.test(newSpill.amountReleased)
        ) {
          setValidationErrors({
            ...validationErrors,
            amountReleased: {
              msg: amountReleasedIsInt
                ? "Length should not exceed 9"
                : "Length before and after point should be less than 9",
            },
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            amountReleased: null,
          });
        }
      }
    }
  };

  const isInt = (n) => {
    const isDotAtTheEnd = n.substring(n.length - 1, n.length);
    n = +n;
    return isDotAtTheEnd === "." ? undefined : n % 1 === 0;
  };

  useEffect(() => {
    if (currentSpill?.data?.spill?.org_id) {
      getDisableContaractor && getDisableContaractor({ org_id: currentSpill?.data?.spill?.org_id });
      getFacility && getFacility({ org_id: currentSpill?.data?.spill?.org_id });
    }
  }, [getDisableContaractor, getFacility, currentSpill?.data?.spill?.org_id]);

  const onFileChangeHandler = (
    event,
    index,
    type,
    adminIndex,
    requestedDocumentsData,
    requestedDocumentsMappedLables
  ) => {
    const adminInvoiceNumber = admin[adminIndex]?.invNo;
    let updatedFilesList = [];
    let tempSelected = [...selectedFiles];
    let contractorInvoiceAmount = null;
    if (!tempSelected[index]) {
      tempSelected[index] = [];
    }
    for (let file of event.target.files) {
      const isFileRequested = requestedDocumentsMappedLables?.some(
        (item) => item?.value === type
      );
      const requestId = requestedDocumentsData?.find(
        (item) => item?.requested_type === type
      )?.id;

      contractorInvoiceAmount = type === "contractorInv" ? contractorInv : null;
      tempSelected[index] = [
        ...tempSelected[index],
        {
          file: file,
          size: file.size,
          type: type,
          inv_amount: contractorInvoiceAmount,
          isFileRequested: isFileRequested,
          requestId: requestId ? requestId : null,
        },
      ];
    }

    const currentFilesNameToBeUpdated = tempSelected[index];

    currentFilesNameToBeUpdated?.map((attachment) => {
      if (attachment?.type === "contractorInv") {
        let name = attachment?.file?.name?.split(
          attachment?.file?.name[attachment?.file?.name?.lastIndexOf(".")]
        )[0];
        const ext = attachment?.file?.name?.split(
          attachment?.file?.name[attachment?.file?.name?.lastIndexOf(".")]
        )[1];
        name = `Initial-Contractor-Invoice-${adminInvoiceNumber ? adminInvoiceNumber : null
          }.${ext}`;
        Object.defineProperty(attachment?.file, "name", {
          writable: true,
          value: name,
        });
      }
    });

    let updatedAttachmentList = renameAttachmentDuplicates(
      currentFilesNameToBeUpdated
    );

    currentFilesNameToBeUpdated?.map((fileItem, index) => {
      fileItem = {
        ...fileItem,
        file: new File([fileItem?.file], updatedAttachmentList[index], {
          type: fileItem?.file?.type,
        }),
      };
      updatedFilesList.push(fileItem);
    });
    tempSelected[index] = updatedFilesList;
    setSelectedFiles(tempSelected);
    event.target.value = "";
  };

  const viewAdminAttachmentHistory = (attachmentId) => {
    getSpillAdminAttachmentHistory(attachmentId);
  };

  useEffect(() => {
    if (spillAdminAttachmentHistory?.data?.length > 0) {
      setAttachmentHistory({
        data: spillAdminAttachmentHistory?.data,
        error: spillAdminAttachmentHistory?.error,
        loading: spillAdminAttachmentHistory?.loading,
      });
    }
  }, [spillAdminAttachmentHistory]);

  const updateRejectedAttachment = (
    filesList,
    fileToUpdateIndex,
    adminIndex,
    fileToUpdate
  ) => {
    let newFileList = [];
    let existingFiles = [];
    let filesToBeReplaced = [];
    let renamedFilesToBeReplaced = [];

    filesToBeReplaced.push(fileToUpdate);
    renamedFilesToBeReplaced = renameAttachmentDuplicates(filesToBeReplaced);

    existingFiles = [...admin[adminIndex]?.attachments];

    if (!existingFiles[fileToUpdateIndex]) {
      existingFiles[fileToUpdateIndex] = [];
    }

    for (let file of filesList) {
      existingFiles[fileToUpdateIndex] = {
        file: file,
        size: file?.size,
        type: file?.type,
        parentFileId: existingFiles[fileToUpdateIndex]?.id,
        attachmentType: fileToUpdate?.type,
        inv_amount: contractorInv ? contractorInv : 0,
      };
    }

    newFileList.push(existingFiles[fileToUpdateIndex]);

    setUpdatedFilesState({
      isNewFilesUploaded: true,
      previousFiles: [
        ...updatedFilesState?.previousFiles,
        ...renamedFilesToBeReplaced,
      ],
      newFiles: [...updatedFilesState?.newFiles, ...newFileList],
    });
  };

  const onFileCancelHandler = (index, fileIndex) => {
    let tempFile = [...selectedFiles];
    tempFile[index].splice(fileIndex, 1);
    setSelectedFiles(tempFile);
  };

  const canSeeTimestamps = canSeeStampsOrAmounts(
    currentUser?.data?.role?.role,
    currentUser?.data?.client_organization?.timestamp_visibility
  );
  const canSeeAmounts = canSeeStampsOrAmounts(
    currentUser?.data?.role?.role,
    currentUser?.data?.client_organization?.monetary_visibility
  );

  const reportingReqData =
    currentSpill?.data?.spill?.reportingRequirement || [];
  let spillOrg = currentSpill.data?.spill.client_organization || {};
  const facilityData = currentSpill?.data?.spill?.facility;
  const wasteHandlingData = currentSpill?.data?.spill?.wasteHandling;
  const showTeamDropdown = currentSpill?.data?.spill?.client_organization?.is_ics_client;

  const disableContractor = (contractorOptions, isDisabled = false) => {
    if (!Array.isArray(disableClientContractors)) {
      return false;
    }
    const isDisabledContract = disableClientContractors?.find(
      (val) => val?.contractor_id === contractorOptions?.contractor_id && val?.address_id === (contractorOptions?.addressId ? contractorOptions?.addressId : null));

    if (isDisabledContract) {
      isDisabled = true;
    }

    if (typeof isDisabled == "boolean" && isDisabled) {
      return true;
    }
    if (contractorOptions.tier_level === "Do Not Use") {
      return true;
    } else if (contractorOptions?.contractor_attachments_expiries?.length) {
      if (
        contractorOptions?.contractor_attachments_expiries[0].expiry_date ===
        null
      ) {
        return false;
      }
      const today = new Date();
      const expDate = new Date(
        contractorOptions?.contractor_attachments_expiries[0]?.expiry_date
      );
      // Set time components to 0
      expDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      if (expDate <= today) {
        return true; // expiry date has passed or is today
      } else {
        return false; // expiry date is in the future
      }
    }
  };

  const setSnackbarMessageCb = (message, severity) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);
    setSnackBarOpen(true);
  };

  const handleContractorChange = (values) => {
    let inActiveCount = 0;
    const newlyRejectedCount =
      values?.filter((o) => o?.accepted === false).length || 0;

    const oldRejectedCount =
      selectedContractors?.filter((o) => o?.accepted === false).length || 0;

    selectedContractors.map((x) => x.is_inactive === true && inActiveCount++);

    if (!values) {
      for (const index in selectedContractors) {
        removeContractor(index);
      }

      if (admin && admin.length) {
        let tempAdmin = [];
        for (const index in admin) {
          if (admin[index].isMain) {
            tempAdmin = [...tempAdmin, admin[index]];
          } else if (admin[index].adminId) {
            tempAdmin = [...tempAdmin, { ...admin[index], isRemoved: true }];
          }
        }
        setAdmin(tempAdmin);
      }
    } else if (
      values.length < selectedContractors.length - inActiveCount ||
      newlyRejectedCount !== oldRejectedCount
    ) {
      let removedContractor;

      //Iterate over all prev selected contractors
      for (const contractorIndex in selectedContractors) {
        if (
          selectedContractors[contractorIndex].is_inactive !== true &&
          values?.findIndex((o) =>
            compareContractor(o, selectedContractors[contractorIndex])
          ) === -1
        ) {
          //if prev active contractor is not found in the list
          removedContractor = { ...selectedContractors[contractorIndex] };

          removeContractor(contractorIndex);
        } else if (selectedContractors[contractorIndex].accepted !== false) {
          // ^ if not rejected

          // Finding index of contractor which was previously not rejected and now rejected
          const index = values?.findIndex(
            (o) =>
              compareContractor(o, selectedContractors[contractorIndex]) &&
              o.accepted === false
          );
          //If found previously
          if (index !== -1) {
            // Remove from selected contractors
            const tempContractors = [...selectedContractors];
            tempContractors.splice(contractorIndex, 1);
            setSelectedContractors(tempContractors);

            //Add to rejected contractors
            rejectedContractors.push(values[index]);
            setRejectedContractors(rejectedContractors);
          }
        }
      }
      if (removedContractor) {
        // If some contractor is removed completely delete relative admin
        // oterwise make it inactive
        let tempAdmin = [...admin];
        for (const item in admin) {
          if (compareContractor(admin[item], removedContractor)) {
            if (tempAdmin[item].adminId) {
              //In active
              tempAdmin[item].isRemoved = true;
            } else {
              //Complete Removal
              tempAdmin.splice(item, 1);
            }
          }
        }
        setAdmin(tempAdmin);
      }
    } else {
      //if not removed
      let addedContractor = null;
      let tempContractors = [...selectedContractors];

      //iterate over the updated array of contractors
      for (const contractorIndex in values) {
        //find index of element if it is newly added
        const index = selectedContractors?.findIndex((o) =>
          compareContractor(o, values[contractorIndex])
        );
        //Newly Added: set that to addedContractor
        if (index === -1) {
          addedContractor = values[contractorIndex];
          tempContractors = [
            ...tempContractors,
            { ...addedContractor, accepted: null },
          ];
        } else {
          //It was present before: Check if it was inactive then set to active or if it is newly accepted
          if (
            tempContractors[index].is_inactive ||
            (values[contractorIndex].accepted &&
              !tempContractors[index].accepted)
          ) {
            addedContractor = values[contractorIndex];
            tempContractors[index].is_inactive = false;
            tempContractors[index].accepted =
              values[contractorIndex].accepted ?? null;
            tempContractors[index].previouslyInactive =
              values[contractorIndex]?.previouslyInactive === false
                ? false
                : true;
          }
        }
      }
      if (!addedContractor) {
        //No newly added, exit the method
        return;
      }
      //Find the relevant admin if present
      let selection = admin?.findIndex((o) =>
        compareContractor(o, addedContractor)
      );
      if (selection !== -1) {
        //Relevant admin found
        let tempAdmins = [...admin];
        tempAdmins[selection].isRemoved = false;
        setAdmin(tempAdmins);
      } else if (addedContractor.accepted) {
        //Check if added contractor is accepted, if not, donot create admin for it
        let tempAdmins = [
          ...admin,
          {
            contractorId: addedContractor.contractor_id,
            contractorAddressId: addedContractor.addressId,
            adminId: null,
            info: "",
            pix: null,
            spillSummary: null,
            contractorInv: null,
            wasteDoc: null,
            contractorInvoice: "",
            invNo: "",
            finalContractorInv: "",
            savings: "",
            pesPaid: null,
            contractorPaid: null,
            transToCt: null,
            payBy: null,
            responseTime: "00:00",
            isRemoved: false,
            isMain: false,
            isComplete: false,
            pesInvNo: null,
            pesInvAmount: null,
            isNotRequired: false,
            isNotRequiredCheck: false,
          },
        ];
        setAdmin(tempAdmins);
      }
      setSelectedContractors(tempContractors);
    }
  };

  const removeContractor = (index) => {
    let tempContractors = [...selectedContractors];
    if (
      currentSpill?.data?.spillContractors?.findIndex(
        (o) => o.value === selectedContractors[index].value
      ) === -1
    ) {
      tempContractors.splice(index, 1);
    } else {
      tempContractors[index].is_inactive = true;
    }
    setSelectedContractors(tempContractors);
  };

  const handleCompleteClick = (index) => {
    let tempAdmin = [...admin];
    tempAdmin[index].status = "submit";
    setAdmin(tempAdmin);
  };
  const getTotal = (key) => {
    let count = 0;
    for (let note of spillNotes) {
      count += parseFloat(note[key]) || 0;
    }
    return count;
  };

  const handleNotesAndAttachmentsChangePage = (updatedRowsPerPage, newPage) => {
    setPage(newPage);
    setIsPageChanged(true);
    const spill_id = currentSpill?.data?.spill?.id;
    if (spill_id) {
      getPaginatedNotes({
        spillId: spill_id,
        page: newPage,
        limit:
          updatedRowsPerPage === null ||
          updatedRowsPerPage === undefined ||
          typeof updatedRowsPerPage === "object"
            ? rowsPerPage
            : updatedRowsPerPage,
      });
    }
  };

  const handleNotesAndAttachmentsChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    handleNotesAndAttachmentsChangePage(parseInt(event.target.value, 10), 0);
  };

  const createInactiveList = () => {
    let arr = [];
    let count = 0;
    let div = (
      <div className={classes.inActiveHeading}>Inactive Contractors : </div>
    );

    arr = selectedContractors.map((con, index) => {
      if (con.is_inactive) {
        count += 1;
        return (
          <div key={index} className={classes.inActive}>
            {con.label}
          </div>
        );
      } else {
        return null;
      }
    });

    if (count > 0) {
      return [div, ...arr];
    }
  };
  const showAdmin = (adminData, user) => {
    if (currentSpill.data.spill.is_legacy && adminData.isMain) {
      return true;
    }
    if (adminData.contractorId === user.contractor_id) {
      if (
        user.role.role === ROLES.CONTRACTOR_ADMIN ||
        (!adminData.contractorAddressId && !user.contractor_address_id) ||
        adminData.contractorAddressId === user.contractor_address_id
      ) {
        return true;
      }
    }
    return false;
  };

  const changePacketReviewerHandler = (value) => {
    const selectedReviewerData = value.split(" ");

    // packet reviewer count
    const packet_reviewer_count_string =
      selectedReviewerData[selectedReviewerData?.length - 1];
    let packet_reviewer_count_number = packet_reviewer_count_string?.replace(
      "(",
      " "
    );
    packet_reviewer_count_number = packet_reviewer_count_number?.replace(
      ")",
      " "
    );
    packet_reviewer_count_number = packet_reviewer_count_number?.replaceAll(
      " ",
      ""
    );
    packet_reviewer_count_number = +packet_reviewer_count_number;

    // packet reviewer full name
    let packet_reviewer_full_name = selectedReviewerData?.slice(
      0,
      selectedReviewerData?.length - 1
    );
    packet_reviewer_full_name = packet_reviewer_full_name?.filter(
      (x) => x.length > 0
    );
    packet_reviewer_full_name = packet_reviewer_full_name?.join(" ");

    const found_packet_reviewer = fetchedPacketReviewersData?.data?.find(
      (reviewer) =>
        reviewer?.full_name === packet_reviewer_full_name &&
        reviewer?.packet_reviewer_count === packet_reviewer_count_number
    );
    const packet_reviewer_id = found_packet_reviewer?.id;

    setPacketReviewerToUpdate({
      id: packet_reviewer_id,
      full_name: packet_reviewer_full_name,
      count: packet_reviewer_count_number,
    });

    setSelectedPacketReviewer(value);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setValue('');
    if (newValue) {
      setValue(newValue?.name);
    }
  };

  // this function triggers when value is selected 
  const handelMaterialClassChange = (event, newValue) => {
    setMaterialClassValue("");
    if(event?.target?.value) {
      setMaterialClassValue(event?.target?.value)
    } else if(newValue) {
      setMaterialClassValue(newValue?.name)
    }
  }

  const handleTextFieldChange = (event) => {
    const newValue = event.target.value;
    getMaterial({ value: newValue });
    
    setValue(newValue);
  };
  

  // this function trigger on each type
  const handleMaterialClassFieldChange = (event) => {
    const newValue = event?.target?.value;
  
    // trim value if its longer than 100
    const trimmedValue = newValue.slice(0, 100);

    setMaterialClassValue(trimmedValue);
  
    // if no trimmed value reset the filter material class data else filter according to trimmed value
    if (!trimmedValue) {
      setFilteredMaterialClassData(materialClassData);
    } else {
      const filteredMaterialClass = materialClassData?.filter(materialClassItem =>
        materialClassItem?.name?.trim()?.toLowerCase()?.includes(trimmedValue)
      );
      setFilteredMaterialClassData(filteredMaterialClass);
    }
  }
  const handleTerminalTeamFieldChange = (event) => {
    const newValue = event?.target?.value;
    if (!newValue) {
      setFilteredTerminalTeams(terminalTeams);
      setTerminalTeamsValue(newValue);
      return;
    }
    const trimmedValue = escapeRegExp(newValue?.trim()?.toLowerCase());
    const regexPattern = new RegExp(`\\b${trimmedValue}\\w*\\b`, 'i');
    const filteredTerminalTeamsData = terminalTeams?.filter((terminalTeam) => {
      return (
        regexPattern?.test(terminalTeam?.terminalName?.toLowerCase()) ||
        (terminalTeam?.team && regexPattern?.test(terminalTeam?.team?.toLowerCase()))
      );
    });
  
    setFilteredTerminalTeams(filteredTerminalTeamsData);
    setTerminalTeamsValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `edit-tab-${index}`,
      "aria-controls": `edit-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Fragment>
        {currentSpill?.loading || loading ? (
          <div className={classes.rootLoader}>
            <CircularProgress style={{ width: "80px", height: "80px" }} />
          </div>
        ) : currentSpill?.success ? (
          <Paper elevation={3} className={classes.spillPaper}>
            <div className="flex">
              <div className={classes.detailHeading}>
                Spill {currentSpill.data?.spill?.job_no} -- Edit Mode
              </div>
              <div className={classes.detailHeading}>
                { showTeamDropdown == true ? ( <span>ICS Client</span> ) : null }
              </div>
            </div>
            <hr />
            <div className="flex">
              <div className={classes.detailHeading}>
                Opened on:
                {getFormattedTime(currentSpill?.data?.spill?.opened_on)}
              </div>
              <div className={classes.detailHeading}>
                Status:
                {statusVisibility(
                  currentSpill?.data?.spill?.status,
                  currentUser?.data?.role?.role
                )}
              </div>
            </div>
            <div className="flex">
              <div>
                {currentUser.data?.role?.permission?.close_spills && (
                  <CustomMenu
                    // allowCloseSpill={allowCloseSpill}
                    options={spillStatuses.map(
                      (x) => `${x?.status}: ${x?.name}`
                    )}
                    handleChange={handleSpillChange("status")}
                    buttonText="Status Change"
                  />
                )}
              </div>

              <div className={classes.detailHeading}>
                {currentSpill.data?.spill?.closed_on === "Still Open" ||
                currentSpill.data?.spill?.closed_on === "Re-Opened"
                  ? currentSpill.data?.spill?.closed_on
                  : `Closed On: ${getFormattedTime(
                    currentSpill.data?.spill?.closed_on
                  )}`}
              </div>
            </div>
            <div className="flex">
              <div
                onClick={() => viewSpill(jobNo)}
                className={classes.linkButton}
              >
                View Spill
              </div>
              {canSeeVoidOption(currentUser?.data?.role?.role) ? (
                <div
                  onClick={() => voidPromptHandler(true)}
                  className={classes.linkButton}
                >
                  Void
                </div>
              ) : (
                <></>
              )}
            </div>

            <div style={{ marginTop: 10 }}>
              <AppBar position="static" color="primary">
                <Tabs
                  value={tabValue}
                  onChange={(e, newVal) => setTabValue(newVal)}
                  aria-label="edit tabs"
                >
                  {tabList.map((row, index) => (
                    <Tab
                      key={index}
                      label={row.label}
                      {...a11yProps(row.index)}
                      value={row.index}
                    ></Tab>
                  ))}
                </Tabs>
              </AppBar>
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                {currentUser?.data?.role?.permission?.edit_own_notes
                  ? null
                  : !currentUser?.data?.role?.role.includes("Contractor") && (
                    <div>
                      <TabPanel
                        className={classes.tabPanel}
                        value={tabValue}
                        index={0}
                      >
                        <Grid
                          container
                          spacing={3}
                          className={classes.tabPanelPaper}
                        >
                          <Grid item xs={6} style={{ textAlign: "left" }}>
                            <InputLabel id="user_id_select_label">
                              Manager *
                            </InputLabel>
                            <Select
                              labelId="user_id_select_label"
                              id="user_id_select"
                              name="user_id"
                              style={{ width: "100%" }}
                              inputProps={{
                                value: spill.user_id,
                              }}
                              onChange={handleSpillChange("user_id")}
                            >
                              {admins.data &&
                                removeProbPMFromOptions(
                                  orderAlphabaticallyByKey(
                                    admins.data,
                                    "full_name"
                                  )
                                )
                                  .filter((admin) => admin.active)
                                  .map((e) => {
                                    return (
                                      <MenuItem value={e.id}>
                                        {e.full_name}
                                      </MenuItem>
                                    );
                                  })}
                            </Select>
                          </Grid>

                          {spill?.status ===
                            "Open: Documentation In Review" ||
                            spill?.status ===
                            "Open: Documentation Sent Back to Contractor for Revision" ? (
                            <Grid item xs={6} style={{ textAlign: "left" }}>
                              <InputLabel id="user_id_select_label">
                                Select Packet Reviewer
                              </InputLabel>
                              <Select
                                labelId="packet_reviewer_label"
                                id="packet_reviewer_select"
                                name="packet_reviewer"
                                style={{
                                  minWidth: "250px",
                                  margin: "0px",
                                  width: "100%",
                                }}
                                inputProps={{
                                  value: selectedPacketReviewer,
                                }}
                                onChange={(event) => {
                                  changePacketReviewerHandler(
                                    event?.target?.value
                                  );
                                }}
                                disabled={
                                  fetchedPacketReviewersData?.loading === true
                                    ? true
                                    : false
                                }
                              >
                                {(fetchedPacketReviewersData?.data?.length > 0
                                  ? fetchedPacketReviewersData?.data?.map(
                                    (reviewer) =>
                                      `${reviewer?.full_name}${" "}(${reviewer?.packet_reviewer_count
                                      })`
                                  )
                                  : []
                                ).map((e) => {
                                  return <MenuItem value={e}>{e}</MenuItem>;
                                })}
                              </Select>
                            </Grid>
                          ) : null}

                          <Grid item xs={12} style={{ textAlign: "left" }}>
                            <InputLabel id="org_id_select_label">
                              Organization *
                            </InputLabel>
                            <Select
                              labelId="org_id_select_label"
                              id="org_id_select"
                              name="org_id"
                              inputProps={{
                                value: spill.org_id,
                              }}
                              onChange={handleSpillChange("org_id")}
                              style={{ width: "100%" }}
                            >
                              {clientOrganizationNames.data &&
                                orderAlphabaticallyByKey(
                                  clientOrganizationNames.data.filter(
                                    (x) => x.active
                                  ),
                                  "name"
                                ).map((e, id) => {
                                  return (
                                    <MenuItem value={e?.id}>{e?.name}</MenuItem>
                                  );
                                })}
                            </Select>
                          </Grid>

                          { isIcsClient == true ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                  <Autocomplete
                                    options={filteredTerminalTeams}
                                    getOptionLabel={(option) => `${option?.terminalName}${option?.team ? ` (${option?.team})` : ''}`}
                                    value={selectedTerminalTeam}
                                    onChange={(event, newValue) => handleTerminalTeamSelect(newValue)}
                                    onBlur={() => setFilteredTerminalTeams(terminalTeams)}
                                    getOptionSelected={(option, value) => option?.terminalId === value?.terminalId && option?.teamId === value?.teamId}                                    
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Terminal (Team)"
                                        value={terminalTeamsValue}
                                        onChange={handleTerminalTeamFieldChange}
                                      />
                                    )}
                                    className="customAutocomplete"
                                  />

                              </Grid>
                            </>
                          ) : (
                            <>
                              {facilityOption.length > 0 ? (
                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: "left" }}
                                >
                                  <InputLabel id="users-for-email-label">
                                    Facility
                                  </InputLabel>
                                  <ReactSelect
                                    value={selectedFacility}
                                    required
                                    isMulti={false}
                                    onChange={(selectedOption) => {
                                      setSelectedFacility(selectedOption);
                                       // Cannot read properties of null *reading 'value')
                                       const facility = facilities?.data.find((f) => f.id === selectedOption?.value);
                                      if (facility) {
                                        setSpill({
                                          ...spill,
                                          address: facility?.address,
                                          city: facility?.city,
                                          zip_code: facility?.zip_code,
                                          country: facility?.country,
                                          state: facility?.state,
                                        });
                                      }
                                    }}
                                    options={orderAlphabaticallyByKey(facilityOption)}
                                    isClearable
                                  />
                                </Grid>
                              ) : null}
                            </>
                          )}

                          <Grid item xs={6} style={{ textAlign: "left" }}>
                            <InputLabel id="user_id_select_label">
                              Status
                            </InputLabel>
                            <Select
                              labelId="status_label"
                              id="status_select"
                              name="status"
                              style={{ width: "100%" }}
                              inputProps={{
                                value: spill.status,
                              }}
                              onChange={(event) =>
                                handleSpillChange("status")(
                                  event.target.value
                                )
                              }
                            >
                              {spillStatuses?.map((e, index, statuses) => {
                                return (
                                  <MenuItem
                                    // disabled={
                                    //   !allowCloseSpill
                                    //     ? statuses.indexOf(
                                    //         statuses[statuses?.length - 1]
                                    //       ) === index
                                    //     : false
                                    // }
                                    value={`${e?.status}: ${e?.name}`}
                                  >
                                    {`${e?.status}: ${e?.name}`}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {/* {!allowCloseSpill ? (
                                <span className={classes.closeStatusWarning}>
                                  * This incident can not be closed unless all
                                  active admins have their attachments approved
                                </span>
                              ) : null} */}
                          </Grid>

                          <Grid item xs={6} style={{ textAlign: "left" }}>
                            <InputLabel id="type_select_label">
                              Type *
                            </InputLabel>
                            <Select
                              labelId="type_select_label"
                              id="type_select"
                              name="type"
                              inputProps={{
                                value: spill.type,
                              }}
                              onChange={handleSpillChange("type")}
                              style={{ width: "100%" }}
                            >
                              <MenuItem value="">
                                <em>Select Type</em>
                              </MenuItem>
                              <MenuItem value={"Highway"}>Highway</MenuItem>
                              <MenuItem value={"Dock"}>Dock</MenuItem>
                              <MenuItem value={"File Review"}>
                                File Review
                              </MenuItem>
                              <MenuItem value={"BioHazard"}>
                                BioHazard
                              </MenuItem>
                              <MenuItem value={"Monthly Tracking File"}>
                                Monthly Tracking File
                              </MenuItem>
                            </Select>
                          </Grid>

                          <Grid item xs={6} className={classes.leftAlignGrid}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBoxAlignment}
                              checked={isSpillEmergency}
                              onChange={(event) =>
                                setIsSpillEmergency(event.target.checked)
                              }
                            />
                            <InputLabel
                              id="non_emergency"
                              className={classes.checkBoxMisc}
                            >
                              Mark incident as Non-Emergency
                            </InputLabel>
                          </Grid>

                          <Grid item xs={6} className={classes.leftAlignGrid}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBoxAlignment}
                              checked={watched}
                              onChange={(event) =>
                                setWatched(event.target.checked)
                              }
                            />
                            <InputLabel
                              id="watched"
                              className={classes.checkBoxMisc}
                            >
                              Mark incident as Watched
                            </InputLabel>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="conditions"
                              name="conditions"
                              label="Conditions"
                              fullWidth
                              autoComplete="conditions"
                              multiline
                              inputProps={{
                                value: spill.conditions,
                              }}
                              onChange={handleSpillChange("conditions")}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="address"
                              name="address"
                              label="Address"
                              fullWidth
                              autoComplete="address"
                              inputProps={{
                                value: spill.address,
                              }}
                              onChange={handleSpillChange("address")}
                            />
                          </Grid>

                          <Grid item xs={12} className={classes.leftAlign}>
                            <InputLabel
                              id="location_type_label"
                              className={classes.locationTypeLabel}
                            >
                              Location Type:
                            </InputLabel>
                            <RadioGroup
                              aria-label="location_type_radio_group"
                              name="locationType"
                              className={classes.radioGroupDisplay}
                              value={spill.locationType}
                              onChange={handleSpillChange("locationType")}
                            >
                              <FormControlLabel
                                value="Client Facility"
                                control={<Radio color="primary" />}
                                label="Client Facility"
                              />
                              <FormControlLabel
                                value="Customers Location"
                                control={<Radio color="primary" />}
                                label="Customers Location"
                              />
                              <FormControlLabel
                                value="Roadway"
                                control={<Radio color="primary" />}
                                label="Roadway"
                              />
                            </RadioGroup>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="city"
                              name="city"
                              label="City"
                              fullWidth
                              autoComplete="city"
                              inputProps={{
                                value: spill.city,
                              }}
                              onChange={handleSpillChange("city")}
                            />
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "left" }}>
                            <InputLabel id="state_select_label">
                              State *
                            </InputLabel>
                            <Select
                              labelId="state_select_label"
                              id="state_select"
                              name="state"
                              inputProps={{
                                value: spill.state,
                              }}
                              onChange={handleSpillChange("state")}
                              style={{ width: "100%" }}
                            >
                              {states &&
                                orderAlphabaticallyByKey(states, "name").map(
                                  (e, id) => {
                                    return (
                                      <MenuItem key={id} value={e?.key}>
                                        {e?.name}
                                      </MenuItem>
                                    );
                                  }
                                )}
                            </Select>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="zip_code"
                              name="zip_code"
                              label="Zip Code"
                              fullWidth
                              autoComplete="zip_code"
                              inputProps={{
                                value: spill.zip_code,
                              }}
                              // InputLabelProps={{
                              //   shrink: true,
                              // }}
                              onChange={handleSpillChange("zip_code")}
                            />
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "left" }}>
                            <InputLabel id="country_select_label">
                              Country *
                            </InputLabel>
                            <Select
                              labelId="country_select_label"
                              id="country_select"
                              name="country"
                              inputProps={{
                                value: spill.country,
                              }}
                              onChange={handleSpillChange("country")}
                              style={{ width: "100%" }}
                            >
                              <MenuItem value={COUNTRIES?.USA}>
                                {COUNTRIES?.USA}
                              </MenuItem>
                              <MenuItem value={COUNTRIES?.CANADA}>{COUNTRIES?.CANADA}</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="contact"
                              name="contact"
                              label="Contact"
                              fullWidth
                              autoComplete="contact"
                              inputProps={{
                                value: spill.contact,
                              }}
                              onChange={handleSpillChange("contact")}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="dock_door_number"
                              name="dock_door_number"
                              label="Dock Door Number"
                              fullWidth
                              autoComplete="Dock Door Number"
                              inputProps={{
                                value: spill.dock_door_number,
                              }}
                              onChange={handleSpillChange("dock_door_number")}

                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="responsible"
                              name="responsible"
                              label="Responsible Party"
                              fullWidth
                              autoComplete="responsible"
                              inputProps={{
                                value: spill.responsible,
                              }}
                              onChange={handleSpillChange("responsible")}
                            />
                          </Grid>

                          <Grid item xs={6} className={classes.leftAlignGrid}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBoxAlignment}
                              checked={spill.drainImpacted}
                              onChange={handleMiscChange("drainImpacted")}
                            />
                            <InputLabel
                              id="drain_impacted_select_label"
                              className={classes.checkBoxMisc}
                            >
                              Drain Impacted
                            </InputLabel>
                          </Grid>
                          <Grid item xs={6} className={classes.leftAlignGrid}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBoxAlignment}
                              checked={spill.waterwayImpacted}
                              onChange={handleMiscChange("waterwayImpacted")}
                            />
                            <InputLabel
                              id="waterway_impacted_select_label"
                              className={classes.checkBoxMisc}
                            >
                              Waterway Impacted
                            </InputLabel>
                          </Grid>
                          <Grid item xs={6}>
                            <CurrencyFieldInput
                              label="Latitude"
                              name="latitude"
                              decimalScale={9}
                              allowNegative={true}
                              value={spill.latitude}
                              onChange={handleSpillChange("latitude")}
                              helperText={
                                validationErrors.latitude?.msg || ""
                              }
                              error={validationErrors.latitude?.showError}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CurrencyFieldInput
                              label="Longitude"
                              name="longitude"
                              allowNegative={true}
                              value={spill.longitude}
                              onChange={handleSpillChange("longitude")}
                              decimalScale={9}
                              helperText={
                                validationErrors.longitude?.msg || ""
                              }
                              error={validationErrors.longitude?.showError}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="tractor"
                              name="tractor"
                              label="Tractor #"
                              fullWidth
                              autoComplete="tractor"
                              inputProps={{
                                value: spill.tractor,
                              }}
                              onChange={handleSpillChange("tractor")}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="trailer"
                              name="trailer"
                              label="Trailer #"
                              fullWidth
                              autoComplete="trailer"
                              inputProps={{
                                value: spill.trailer,
                              }}
                              onChange={handleSpillChange("trailer")}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="driverName"
                              name="driverName"
                              label="Driver Name"
                              fullWidth
                              autoComplete="driverName"
                              inputProps={{
                                value: spill.driverName,
                              }}
                              onChange={handleSpillChange("driverName")}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="driverPhone"
                              name="driverPhone"
                              label="Driver Phone"
                              fullWidth
                              autoComplete="driverPhone"
                              inputProps={{
                                value: spill.driverPhone,
                              }}
                              onChange={handleSpillChange("driverPhone")}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="onsitePocName"
                              name="onsitePocName"
                              label="Onsite POC Name"
                              fullWidth
                              autoComplete="onsitePocName"
                              inputProps={{
                                value: spill.onsitePocName,
                              }}
                              onChange={handleSpillChange("onsitePocName")}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="onsitePocPhone"
                              name="onsitePocPhone"
                              label="Onsite POC Phone "
                              fullWidth
                              autoComplete="onsitePocPhone"
                              inputProps={{
                                value: spill.onsitePocPhone,
                              }}
                              onChange={handleSpillChange("onsitePocPhone")}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="pro"
                              name="pro"
                              label="PRO #"
                              fullWidth
                              autoComplete="pro"
                              inputProps={{
                                value: spill.pro,
                              }}
                              onChange={handleSpillChange("pro")}
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      {!currentUser?.data?.role?.role.includes(
                        "Contractor"
                      ) && (
                          <>
                            <TabPanel
                              value={tabValue}
                              index={1}
                              className={classes.tabPanel}
                            >
                              <Grid
                                container
                                spacing={3}
                                className={classes.tabPanelPaper}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: "left" }}
                                >
                                  <InputLabel id="agencyNational_select_label">
                                    National Agency
                                  </InputLabel>
                                  <Select
                                    labelId="agencyNational_select_label"
                                    id="agencyNational_select"
                                    name="agencyNational"
                                    style={{ width: "100%" }}
                                    inputProps={{
                                      value:
                                        connection.agencyNational?.id ||
                                        connection.agencyNational,
                                    }}
                                    onChange={handleConnectionChange(
                                      "agencyNational"
                                    )}
                                  >
                                    <MenuItem value="">
                                      <em>Select National Agency</em>
                                    </MenuItem>
                                    {agencies.data &&
                                      orderAlphabaticallyByKey(
                                        agencies?.data?.filter(
                                          (agency) =>
                                            agency.jurisdiction === "National"
                                        ),
                                        "state",
                                        "name"
                                      ).map((e, index) => {
                                        return (
                                          <MenuItem key={index} value={e?.id}>
                                            {`${e?.name} (${e?.state})`}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </Grid>

                                <Grid item xs={6}>
                                  <TextField
                                    id="nationalIncidentNo"
                                    name="nationalIncidentNo"
                                    label="National Incident No"
                                    fullWidth
                                    autoComplete="nationalIncidentNo"
                                    value={
                                      connection?.nationalIncidentNo !==
                                        "undefined" &&
                                      connection?.nationalIncidentNo !== "null"
                                        ? connection?.nationalIncidentNo
                                        : null
                                    }
                                    onChange={handleConnectionChange(
                                      "nationalIncidentNo"
                                    )}
                                    disabled
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: "left" }}
                                >
                                  <InputLabel id="agencyId_select_label">
                                    State Agency
                                  </InputLabel>
                                  <Select
                                    labelId="agencyId_select_label"
                                    id="agencyId_select"
                                    name="agencyId"
                                    style={{ width: "100%" }}
                                    inputProps={{
                                      value: connection.agencyId,
                                    }}
                                    onChange={handleConnectionChange(
                                      "agencyId"
                                    )}
                                  >
                                    <MenuItem value="">
                                      <em>Select State Agency</em>
                                    </MenuItem>
                                    {/* filter only state jurisdiction */}
                                    {agencies?.data &&
                                      orderAlphabaticallyByKey(
                                        agencies?.data?.filter(
                                          (agency) =>
                                            agency.jurisdiction === "State"
                                        ),
                                        "state",
                                        "name"
                                      ).map((e, index) => {
                                        return (
                                          <MenuItem key={index} value={e?.id}>
                                            {`${e?.name} (${e?.state})`}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </Grid>

                                <Grid item xs={6}>
                                  <TextField
                                    id="stateIncidentNo"
                                    name="stateIncidentNo"
                                    label="State Incident No"
                                    fullWidth
                                    autoComplete="stateIncidentNo"
                                    value={
                                      connection?.stateIncidentNo !==
                                        "undefined" &&
                                      connection?.stateIncidentNo !== "null"
                                        ? connection?.stateIncidentNo
                                        : null
                                    }
                                    onChange={handleConnectionChange(
                                      "stateIncidentNo"
                                    )}
                                    disabled
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: "left" }}
                                >
                                  <InputLabel id="contractos_label">
                                    Contractors
                                  </InputLabel>

                                  <ReactSelect
                                    value={selectedContractors.filter(
                                      (o) => !o.is_inactive
                                    )}
                                    isMulti
                                    onChange={handleContractorChange}
                                    filterOption={filterOption}
                                    options={contractorOptions}
                                    isOptionDisabled={disableContractor}
                                    styles={colourStyles()}
                                  />
                                  <div>{createInactiveList()}</div>
                                </Grid>
                                <Grid item xs={12}>
                                  <ContractorAcceptance
                                    contractors={selectedContractors.filter(
                                      (o) => !o.is_inactive
                                    )}
                                    onChange={handleContractorChange}
                                    rejected={[
                                      ...rejectedContractors,
                                      ...prevRejectedContractors,
                                    ]}
                                  />
                                </Grid>
                              </Grid>
                            </TabPanel>

                            <TabPanel
                              value={tabValue}
                              index={2}
                              className={classes.tabPanel}
                            >
                              <Grid
                                container
                                spacing={3}
                                className={classes.tabPanelPaper}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                >
                                  <Tooltip
                                    title={
                                      currentSpill.data.spill.longitude ===
                                        null ||
                                      currentSpill.data.spill.latitude ===
                                        null ? (
                                        <h4>
                                          'Latitude and Longitude should be
                                          added first'
                                        </h4>
                                      ) : (
                                        ""
                                      )
                                    }
                                    placement="top-start"
                                    arrow
                                  >
                                    <div>
                                      <Checkbox
                                        color="primary"
                                        className={classes.checkBoxAlignment}
                                        checked={spill.mapNeeded}
                                        disabled={
                                          currentSpill.data.spill.longitude ===
                                          null ||
                                          currentSpill.data.spill.latitude ===
                                          null
                                        }
                                        onChange={handleMiscChange("mapNeeded")}
                                      />
                                      <InputLabel
                                        id="map_needed_select_label"
                                        className={classes.checkBoxMisc}
                                      >
                                        Map Needed
                                      </InputLabel>
                                    </div>
                                  </Tooltip>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.checkBoxAlignment}
                                    checked={spill.need_5800}
                                    onChange={handleMiscChange("need_5800")}
                                  />
                                  <InputLabel
                                    id="need_5800_select_label"
                                    className={classes.checkBoxMisc}
                                  >
                                    Need 5800
                                  </InputLabel>
                                </Grid>

                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.checkBoxAlignment}
                                    checked={spill.is_waste}
                                    onChange={handleMiscChange("is_waste")}
                                  />
                                  <InputLabel
                                    id="is_waste_select_label"
                                    className={classes.checkBoxMisc}
                                  >
                                    Is Waste
                                  </InputLabel>
                                </Grid>

                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.checkBoxAlignment}
                                    checked={spill.has_msds}
                                    onChange={handleMiscChange("has_msds")}
                                  />
                                  <InputLabel
                                    id="has_msds_select_label"
                                    className={classes.checkBoxMisc}
                                  >
                                    Has Msds
                                  </InputLabel>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.checkBoxAlignment}
                                    checked={spill.is_hazmat}
                                    onChange={handleMiscChange("is_hazmat")}
                                  />
                                  <InputLabel
                                    id="is_hazmat_select_label"
                                    className={classes.checkBoxMisc}
                                  >
                                    Is Hazmat
                                  </InputLabel>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.checkBoxAlignment}
                                    checked={spill.response_sent}
                                    onChange={handleMiscChange("response_sent")}
                                  />
                                  <InputLabel
                                    id="response_sent_select_label"
                                    className={classes.checkBoxMisc}
                                  >
                                    Response Sent
                                  </InputLabel>
                                </Grid>

                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.checkBoxAlignment}
                                    checked={spill.subrogation}
                                    onChange={handleMiscChange("subrogation")}
                                  />
                                  <InputLabel
                                    id="subrogation"
                                    className={classes.checkBoxMisc}
                                  >
                                    Potential Subrogation
                                  </InputLabel>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.checkBoxAlignment}
                                    onChange={handleMiscChange("released_outside_building")}
                                    checked={spill.released_outside_building}

                                  />
                                  <InputLabel
                                    className={classes.checkBoxMisc}
                                  >
                                    Released Outside of building or Trailer
                                  </InputLabel>
                                </Grid>

                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.checkBoxAlignment}
                                    onChange={handleMiscChange("release_offsite")}
                                    checked={spill.release_offsite}
                                  />
                                  <InputLabel
                                    className={classes.checkBoxMisc}
                                  >
                                    Release Made it offsite
                                  </InputLabel>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  className={classes.leftAlignGrid}
                                />

                                <Grid item xs={6}>
                                  <TextField
                                    id="claim_no"
                                    name="claim_no"
                                    label="Claim No"
                                    fullWidth
                                    autoComplete="claim_no"
                                    inputProps={{
                                      value: spill.claim_no,
                                    }}
                                    onChange={handleSpillChange("claim_no")}
                                  />
                                </Grid>
                                <Grid item xs={6} />

                                <Grid item xs={6}>
                                  <TextField
                                    name="shipper_name"
                                    label="Shipper Name"
                                    fullWidth
                                    autoComplete="Shipper Name"
                                    onChange={handleSpillChange("shipper_name")}
                                    inputProps={{
                                      value: spill.shipper_name,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={6}>
                                  <TextField
                                    name="shipper_address"
                                    label="Shipper Address"
                                    fullWidth
                                    autoComplete="Shipper Address"
                                    onChange={handleSpillChange("shipper_address")}
                                    inputProps={{
                                      value: spill.shipper_address,
                                    }}
                                  />
                                </Grid>
                                {currentUser?.data?.role?.role === ROLES.SUPER_USER ?
                                  (<Grid item xs={6} style={{ textAlign: "left" }}>
                                    <Autocomplete
                                      options={filteredMaterialClassData}
                                      getOptionLabel={(option) => option.name}
                                      value={materialClassValue !== "" ? { name: materialClassValue } : null}
                                      onChange={handelMaterialClassChange}
                                      getOptionSelected={() => true}
                                      renderInput={(params) => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <TextField
                                            {...params}
                                            label="Material Class"
                                            value={materialClassValue}
                                            onChange={handleMaterialClassFieldChange}
                                          />
                                          <IconButton
                                            onClick={() => setShowMaterialClassEditPopup(true)}
                                            size='small'
                                            style={{ marginLeft: '10px' }}
                                            title="Edit Material Class" 
                                          >
                                            <Edit fontSize="small" />
                                          </IconButton>
                                        </div>
                                      )}
                                      className="customAutocomplete"
                                    />
                                  </Grid>) : (<Grid item xs={6} style={{ textAlign: "left" }}>
                                    <InputLabel id="material_class_select_label">
                                      Material Class
                                    </InputLabel>
                                    <Select
                                      labelId="material_class_select_label"
                                      id="material_class_select"
                                      name="materialClass"
                                      inputProps={{
                                        value: materialClassValue,
                                      }}
                                      onChange={handelMaterialClassChange}
                                      style={{ width: "100%" }}
                                    >
                                      <MenuItem value={""}>
                                        <em>Select Material Class</em>
                                      </MenuItem>
                                      {materialClassData?.length !== 0 &&
                                        orderAlphabatically(materialClassData, "name")?.map(
                                          (item, index) => {
                                            return (
                                              <MenuItem key={index} value={item?.name}>
                                                {item?.name}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                    </Select>
                                  </Grid>)
                                }
                                <Grid item xs={6} style={{ textAlign: "left" }}>
                                  <Autocomplete
                                    options={suggestions}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                      value !== "" ? { name: value } : null
                                    }
                                    onChange={handleAutocompleteChange}
                                    getOptionSelected={() => true}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Material"
                                        value={value}
                                        onChange={handleTextFieldChange}
                                      />
                                    )}
                                    className="customAutocomplete"
                                  />
                                </Grid>

                                <Grid item xs={6}>
                                  <TextField
                                    id="un_no"
                                    name="un_no"
                                    label="UN/NA No"
                                    fullWidth
                                    autoComplete="un_no"
                                    inputProps={{
                                      value: spill.un_no,
                                    }}
                                    onChange={handleSpillChange("un_no")}
                                    required={
                                      spill.need_5800 || spill.is_hazmat
                                    }
                                    helperText={
                                      validationErrors.un_no?.msg || ""
                                    }
                                    error={validationErrors.un_no?.showError}
                                  />
                                </Grid>

                                <Grid item xs={6}>
                                  <CurrencyFieldInput
                                    name="amountReleased"
                                    label="Reported Amount Released"
                                    allowNegative={false}
                                    value={spill.amountReleased}
                                    onChange={handleSpillChange(
                                      "amountReleased"
                                    )}
                                    helperText={
                                      validationErrors.amountReleased?.msg || ""
                                    }
                                    error={
                                      validationErrors.amountReleased?.showError
                                    }
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <CurrencyFieldInput
                                    name="actual_amount_released"
                                    label="Actual Amount Released"
                                    allowNegative={false}
                                    value={spill.actual_amount_released}
                                    onChange={handleSpillChange(
                                      "actual_amount_released"
                                    )}
                                    helperText={
                                      validationErrors.actual_amount_released?.msg || ""
                                    }
                                    error={
                                      validationErrors.actual_amount_released?.showError
                                    }
                                  />
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "left" }}>
                                  <InputLabel id="quantity_type_released_select_label">
                                    Quantity Type Released
                                  </InputLabel>
                                  <Select
                                    labelId="quantity_type_released_select_label"
                                    id="quantity_type_released_select"
                                    name="quantityTypeReleased"
                                    inputProps={{
                                      value: spill.quantityTypeReleased,
                                    }}
                                    onChange={handleSpillChange(
                                      "quantityTypeReleased"
                                    )}
                                    style={{ width: "100%" }}
                                  >
                                    <MenuItem value="">
                                      <em>Select Quantity Type Released</em>
                                    </MenuItem>
                                    {quantityTypes &&
                                      orderAlphabatically(quantityTypes).map(
                                        (type, index) => {
                                          return (
                                            <MenuItem key={index} value={type}>
                                              {type}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </Select>
                                </Grid>

                                <Grid item xs={6} style={{ textAlign: "left" }}>
                                  <InputLabel id="actual_quantity_type_released_select_label">
                                   Actual Quantity Type Released
                                  </InputLabel>
                                  <Select
                                    labelId="actual_quantity_type_released_select_label"
                                    id="actual_quantity_type_released"
                                    name="actual_quantity_type_released"
                                    inputProps={{
                                      value: spill.actual_quantity_type_released,
                                    }}
                                    onChange={handleSpillChange(
                                      "actual_quantity_type_released"
                                    )}
                                    style={{ width: "100%" }}
                                  >
                                    <MenuItem value="">
                                      <em>Select Actual Quantity Type Released</em>
                                    </MenuItem>
                                    {quantityTypes &&
                                      orderAlphabatically(quantityTypes).map(
                                        (type, index) => {
                                          return (
                                            <MenuItem key={index} value={type}>
                                              {type}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </Select>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "left" }}>
                                  <InputLabel id="damaged_container_type_select_label">
                                    Damaged Container Type
                                  </InputLabel>
                                  <Select
                                    labelId="damaged_container_type_select_label"
                                    id="damaged_container_type_select"
                                    name="damagedContainerType"
                                    inputProps={{
                                      value: spill.damagedContainerType,
                                    }}
                                    onChange={handleSpillChange(
                                      "damagedContainerType"
                                    )}
                                    style={{ width: "100%" }}
                                  >
                                    <MenuItem value="">
                                      <em>Select Damaged Container Type</em>
                                    </MenuItem>
                                    {damagedContainerTypes &&
                                      orderAlphabatically(
                                        damagedContainerTypes
                                      ).map((type, index) => {
                                        return (
                                          <MenuItem key={index} value={type}>
                                            {type}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "left" }}>
                                  <InputLabel id="damage_type_select_label">
                                    Type of Damage
                                  </InputLabel>
                                  <Select
                                    labelId="damage_type_select_label"
                                    id="damage_type_select"
                                    name="damageType"
                                    inputProps={{
                                      value: spill.damageType,
                                    }}
                                    onChange={handleSpillChange("damageType")}
                                    style={{ width: "100%" }}
                                  >
                                    <MenuItem value="">
                                      <em>Select Type of Damage</em>
                                    </MenuItem>
                                    {damageTypes &&
                                      orderAlphabatically(damageTypes).map(
                                        (type, index) => {
                                          return (
                                            <MenuItem key={index} value={type}>
                                              {type}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </Select>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                  <div className="area-covered-content">

                                    <div>
                                      <p><strong>Total Permeabale Surfaces </strong></p>
                                      <p><strong>Total Impermeabale Surfaces </strong></p>
                                      <p><strong>Total Area Covered by Release </strong> </p>
                                    </div>

                                    <div className="area-content-col">
                                      <p> : {totalArea?.total_permeable_area || "Not Added" }</p>
                                      <p> : {totalArea?.total_impermeable_area || "Not Added"}</p>
                                      <p> : {totalArea?.total || 0}</p>
                                    </div>
                                  </div>
                                </Grid>

                              </Grid>
                            </TabPanel>
                          </>
                        )}
                    </div>
                  )}

                {!isCorporateUser(currentUser?.data?.role?.role) && (
                  <TabPanel value={tabValue} index={3}>
                    <AdminTab
                      setSnackbarMessageCb={setSnackbarMessageCb}
                      admin={admin}
                      tabValue={tabValue}
                      setAdmin={setAdmin}
                      showAdmin={showAdmin}
                      currentUser={currentUser}
                      selectedFiles={selectedFiles}
                      contractorInv={contractorInv}
                      attachmentTypes={attachmentTypes}
                      setContractorInv={setContractorInv}
                      contractorOptions={contractorOptions}
                      attachmentHistory={attachmentHistory}
                      onFileCancelHandler={onFileCancelHandler}
                      onFileChangeHandler={onFileChangeHandler}
                      handleCompleteClick={handleCompleteClick}
                      missingInvoiceAlert={missingInvoiceAlert}
                      setMissingInvoiceAlert={setMissingInvoiceAlert}
                      updateRejectedAttachment={updateRejectedAttachment}
                      viewAdminAttachmentHistory={viewAdminAttachmentHistory}
                      partialBillingDownloadData={partialBillingDownloadData}
                    />
                  </TabPanel>
                )}
                {!currentUser?.data?.role?.role.includes("Contractor") && (
                  <>
                    <TabPanel
                      value={tabValue}
                      index={4}
                      className={classes.tabPanel}
                    >
                      <div className={classes.tabPanelPaper}>
                        {currentUser?.data?.role?.permission
                          ?.edit_own_notes ? null : (
                          <div>
                            <div style={{ textAlign: "left", display: "flex" }}>
                              <Typography
                                variant="subtitle2"
                                style={{ lineHeight: 3 }}
                              >
                                Send Attachments:
                              </Typography>
                              <Checkbox
                                color="primary"
                                checked={spill.send_attachment}
                                onChange={() =>
                                  setSpill((val) => ({
                                    ...val,
                                    ["send_attachment"]: !spill.send_attachment,
                                  }))
                                }
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </div>
                            <Grid item xs={12} style={{ textAlign: "left" }}>
                              <InputLabel id="users-for-email-label">
                                Users for Email
                              </InputLabel>
                              <ReactSelect
                                value={selectedUsers}
                                isMulti
                                onChange={handleUpdateUsersToEmail}
                                options={orderAlphabaticallyByKey(userOptions)}
                              />
                            </Grid>
                          </div>
                        )}

                        <Grid item xs={4} style={{ paddingTop: "10px" }}>
                          <CurrencyFieldInput
                            name="rate"
                            label="Rate"
                            decimalScale={5}
                            value={spill.rate}
                            onChange={handleSpillChange("rate")}
                            helperText={validationErrors.rate?.msg || ""}
                            error={validationErrors.rate?.showError}
                          />
                        </Grid>

                        {paginatedNotes.loading ? (
                          <div>
                            <div className={classes.rootLoader}>
                              <CircularProgress
                                style={{ width: "80px", height: "80px" }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div style={{ textAlign: "left" }}>
                            <span>
                              <Typography
                                variant="subtitle2"
                                style={{ lineHeight: 3 }}
                              >
                                Notes:
                              </Typography>

                              <div>
                                {spillNotes.length > 0 ? (
                                  <div>
                                    <div>
                                      <Card
                                        className={classes.headerCard}
                                        style={{ margin: "15px 0px" }}
                                      >
                                        <CardContent
                                          className={classes.headerCardBody}
                                        >
                                          <Grid container spacing={3}>
                                            {canSeeTimestamps && (
                                              <Grid item xs={2}>
                                                <div
                                                  className={classes.leftAlign}
                                                >
                                                  Date
                                                </div>
                                              </Grid>
                                            )}
                                            {canSeeAmounts && (
                                              <Grid item xs={2}>
                                                <div
                                                  className={classes.leftAlign}
                                                >
                                                  Hour
                                                </div>
                                              </Grid>
                                            )}
                                            {canSeeAmounts && (
                                              <Grid item xs={2}>
                                                <div
                                                  className={classes.leftAlign}
                                                >
                                                  Rate
                                                </div>
                                              </Grid>
                                            )}
                                            {canSeeAmounts && (
                                              <Grid item xs={2}>
                                                <div
                                                  className={classes.leftAlign}
                                                >
                                                  Amount
                                                </div>
                                              </Grid>
                                            )}
                                            <Grid item xs={4}>
                                              <div
                                                className={classes.leftAlign}
                                              >
                                                Service
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <div className={classes.notesListScroll}>
                                        {spillNotes &&
                                          spillNotes.map((note, i) => (
                                            <Card
                                              className={classes.headerCard}
                                              style={{ margin: "15px 0px" }}
                                            >
                                              <CardContent
                                                className={classes.cardBody}
                                              >
                                                <Grid container spacing={3}>
                                                  {canSeeTimestamps && (
                                                    <Grid item xs={2}>
                                                      <div
                                                        className={
                                                          classes.leftAlign
                                                        }
                                                      >
                                                        {note.created_at
                                                          ? Moment(
                                                            new Date(
                                                              note.created_at
                                                            )
                                                          )?.format(
                                                            "MM-DD-YYYY"
                                                          )
                                                          : Moment(
                                                            new Date()
                                                          )?.format(
                                                            "MM-DD-YYYY"
                                                          )}
                                                      </div>
                                                    </Grid>
                                                  )}
                                                  {canSeeAmounts && (
                                                    <Grid item xs={2}>
                                                      <div
                                                        className={
                                                          classes.leftAlign
                                                        }
                                                      >
                                                        {note.type === "fixed"
                                                          ? "Fixed"
                                                          : note.hour
                                                          ? `${note.hour} h`
                                                          : "0.00 h"}
                                                      </div>
                                                    </Grid>
                                                  )}

                                                  {canSeeAmounts && (
                                                    <Grid item xs={2}>
                                                      <div
                                                        className={
                                                          classes.leftAlign
                                                        }
                                                      >
                                                        {note.rate
                                                          ? `$${note.rate}`
                                                          : "$0.00"}
                                                      </div>
                                                    </Grid>
                                                  )}
                                                  {canSeeAmounts && (
                                                    <Grid item xs={2}>
                                                      <div
                                                        className={
                                                          classes.leftAlign
                                                        }
                                                      >
                                                        {note.amount
                                                          ? `$${note.amount}`
                                                          : "$0.00"}
                                                      </div>
                                                    </Grid>
                                                  )}
                                                  <Grid item xs={4}>
                                                    <div
                                                      className={
                                                        classes.leftAlign
                                                      }
                                                    >
                                                      {note.service_type}
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </CardContent>
                                            </Card>
                                          ))}
                                      </div>
                                    </div>

                                    {canSeeAmounts && (
                                      <Card className={classes.totalCard}>
                                        <Grid container spacing={3}>
                                          <Grid
                                            item
                                            xs={2}
                                            className={classes.totalLabel}
                                          >
                                            &nbsp;&nbsp;TOTAL
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            className={classes.totalArea}
                                          >
                                            <div>
                                              {
                                                currentSpill?.data?.spill
                                                  ?.spill_notes_data?.hour
                                              }
                                              h
                                            </div>
                                          </Grid>
                                          <Grid item xs={2} />

                                          <Grid
                                            item
                                            xs={2}
                                            className={classes.totalArea}
                                          >
                                            <div>
                                              $
                                              {
                                                currentSpill?.data?.spill
                                                  ?.spill_notes_data?.amount
                                              }
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    )}

                                    {/* uncomment this code to get pagination back make sure to 
                                    pass shouldPaginate true in saga api call */}
                                    {/* {paginatedNotes.loading ? null : (
                                      <div
                                        className={
                                          classes.notePaginationWrapper
                                        }>
                                        <div
                                          className={classes.paginationWrapper}>
                                          <TablePagination
                                            classes={{
                                              toolbar:
                                                classes.paginationToolBar,
                                              select: classes.paginationSelect,
                                            }}
                                            rowsPerPageOptions={[5, 10, 25, 50]}
                                            count={totalNotes}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                              inputProps: {
                                                'aria-label': 'rows per page',
                                              },
                                              native: true,
                                            }}
                                            onChangePage={
                                              handleNotesAndAttachmentsChangePage
                                            }
                                            onChangeRowsPerPage={
                                              handleNotesAndAttachmentsChangeRowsPerPage
                                            }
                                            ActionsComponent={
                                              TablePaginationActions
                                            }
                                            labelDisplayedRows={(labels) => {
                                              const { from, to, count, page } =
                                                labels;
                                              if (from && to && count) {
                                                return (
                                                  <PaginationButtons
                                                    to={to}
                                                    from={from}
                                                    count={count}
                                                    rowsPerPage={rowsPerPage}
                                                    handleChangePage={
                                                      handleNotesAndAttachmentsChangePage
                                                    }
                                                    page={page}
                                                    setIsAppendNoteLast={
                                                      setIsAppendNoteLast
                                                    }
                                                  />
                                                );
                                              }
                                              return '';
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )} */}
                                  </div>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    style={{ lineHeight: 3 }}
                                  >
                                    Notes not attached
                                  </Typography>
                                )}
                              </div>
                            </span>
                          </div>
                        )}
                      </div>
                    </TabPanel>
                    {currentUser?.data?.role?.permission
                      ?.edit_own_notes ? null : (
                      <TabPanel
                        value={tabValue}
                        index={5}
                        className={classes.tabPanel}
                      >
                        <div className={classes.tabPanelPaper}>
                          <div style={{ textAlign: "left", display: "flex" }}>
                            <Typography
                              variant="subtitle2"
                              style={{ lineHeight: 3 }}
                            >
                              For full "Reserves" detail, use
                              <span
                                onClick={() => viewSpill(jobNo)}
                                className={classes.linkButton}
                              >
                                Display Mode
                              </span>
                              .
                            </Typography>
                          </div>
                          <div style={{ textAlign: "left" }}>
                            <span>
                              <div>
                                {spillReserves?.length > 0 ? (
                                  <div>
                                    <Card
                                      className={classes.headerCard}
                                      style={{ margin: "10px 15px" }}
                                    >
                                      <CardContent
                                        style={{
                                          padding: "10px",
                                          backgroundColor: "#e6e6e6d6",
                                          fontWeight: 600,
                                          color: "#6aa16c",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        ></div>
                                        <Grid container spacing={3}>
                                          <Grid item xs={2}>
                                            <div
                                              className={classes.leftAlign}
                                            ></div>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <div className={classes.leftAlign}>
                                              Date
                                            </div>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <div className={classes.leftAlign}>
                                              Amount
                                            </div>
                                          </Grid>
                                          <Grid item xs={5}>
                                            <div className={classes.leftAlign}>
                                              Approved by
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                    {spillReserves?.map((spill) => (
                                      <Card
                                        className={classes.headerCard}
                                        style={{
                                          margin: "10px 15px",
                                        }}
                                      >
                                        <CardContent
                                          style={{
                                            padding: "10px",
                                          }}
                                        >
                                          <Grid container spacing={1}>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={3}>
                                              <div
                                                className={classes.leftAlign}
                                              >
                                                {Moment(
                                                  new Date(spill?.created_at)
                                                )?.format("DD-MM-YY")}
                                              </div>
                                            </Grid>
                                            <Grid item xs={2}>
                                              <div
                                                className={classes.leftAlign}
                                              >
                                                {spill.amount}
                                              </div>
                                            </Grid>
                                            <Grid item xs={5}>
                                              <div
                                                className={classes.leftAlign}
                                              >
                                                {spill?.user?.full_name}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                    ))}
                                  </div>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    style={{ lineHeight: 3 }}
                                  >
                                    No reserves
                                  </Typography>
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                      </TabPanel>
                    )}
                  </>
                )}
                <TabPanel
                  value={tabValue}
                  index={6}
                  className={classes.tabPanel}
                >
                  <div className={classes.tabPanelPaper}>
                    <>
                      {reportingReqData.length > 0 ? (
                        <div>
                          {reportingReqData.map((reportingreq, index) => (
                            <div
                              key={index}
                              style={{ textAlign: "left" }}
                              className="tabDetail-format"
                            >
                              <h3>Reporting Requirement Information</h3>
                              <p>
                                <strong>Agency Name : </strong>
                                {reportingreq?.name}
                              </p>
                              <p>
                                <strong>Jurisdiction : </strong>
                                {reportingreq?.jurisdiction}
                              </p>
                              <p>
                                <strong>Petroleum Release Amount : </strong>
                                {reportingreq?.petroleum_release_amount}
                              </p>
                              <p>
                                <strong>Petroleum Release Detail : </strong>
                                {reportingreq?.petroleum_release}
                              </p>
                              <p>
                                <strong>Hazardous Material Amount : </strong>
                                {reportingreq?.hazardous_material_amount}
                              </p>
                              <p>
                                <strong>Hazardous Material Detail : </strong>
                                {reportingreq?.hazardous_material}
                              </p>
                              <p>
                                <strong>Water Impact Amount : </strong>
                                {reportingreq?.water_impact_amount}
                              </p>
                              <p>
                                <strong>Water Impact Detail : </strong>
                                {reportingreq?.water_impact}
                              </p>
                              <p>
                                <strong>Soil Impact Amount : </strong>
                                {reportingreq?.soil_impact_amount}
                              </p>
                              <p>
                                <strong>Soil Impact Detail : </strong>
                                {reportingreq?.soil_impact}
                              </p>
                              <p>
                                <strong>Revision Date : </strong>
                                {reportingreq?.revision_date}
                              </p>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p> No reporting requirements available.</p>
                      )}

                      <div>
                        <div
                          style={{ textAlign: "left" }}
                          className="tabDetail-format"
                        >
                          <h3>Special Instructions for Client</h3>
                          {wasteHandlingData?.hazardous_waste == null &&
                          wasteHandlingData?.non_hazardous_waste == null &&
                          spillOrg?.revision_date == null &&
                          spillOrg?.reporting == null &&
                          spillOrg?.reporting_instruction == null &&
                          spillOrg?.report_5800 == null &&
                          spillOrg?.instruction_5800 == null &&
                          spillOrg?.miscellaneous == null ? (
                            <p>No Data Available</p>
                          ) : (
                            <>
                              <p
                                className={
                                  wasteHandlingData?.hazardous_waste == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Haz waste : </strong>
                                {wasteHandlingData?.hazardous_waste}
                              </p>
                              <p
                                className={
                                  wasteHandlingData?.non_hazardous_waste == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Non-Haz Waste : </strong>
                                {wasteHandlingData?.non_hazardous_waste}
                              </p>
                              <p
                                className={
                                  spillOrg?.revision_date == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Revision Date : </strong>
                                {spillOrg.revision_date}
                              </p>
                              <p
                                className={
                                  spillOrg?.reporting == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Reporting : </strong>
                                {spillOrg.reporting === 1 ? "Yes" : "No"}
                              </p>
                              <p
                                className={
                                  spillOrg?.reporting_instruction == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Reporting Instruction : </strong>
                                {spillOrg.reporting_instruction}
                              </p>
                              <p
                                className={
                                  spillOrg?.report_5800 == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Report 5800 : </strong>
                                {spillOrg.report_5800 === 1 ? "Yes" : "No"}
                              </p>
                              <p
                                className={
                                  spillOrg?.instruction_5800 == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>5800 Instruction : </strong>
                                {spillOrg.instruction_5800}
                              </p>
                              <p
                                className={
                                  spillOrg?.miscellaneous == null
                                    ? "hidden"
                                    : "show"
                                }
                              >
                                <strong>Miscellaneous : </strong>
                                {spillOrg.miscellaneous}
                              </p>
                            </>
                          )}
                        </div>
                      </div>

                      {/* team and Terminal */}
                      {(showTeamDropdown == true ||
                      showTeamClientDropdown == true) && selectedTerminalTeam ? (
                        <div
                          style={{ textAlign: "left" }}
                          className="tabDetail-format"
                        >
                          <h3>Team Information</h3>
                          <p
                            className={
                              TeamTerminalData?.terminalName == null ? "hidden" : "show"
                            }
                          >
                            <strong>Terminal Name: </strong>
                              {TeamTerminalData?.terminalName}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.team == null ? "hidden" : "show"
                            }
                          >
                            <strong>Team Name: </strong>
                              {TeamTerminalData?.team}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.address == null
                                ? "hidden"
                                : "show"
                            }
                          >
                            <strong>Address: </strong>
                            {TeamTerminalData?.address}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.city == null ? "hidden" : "show"
                            }
                          >
                            <strong>City: </strong>
                            {TeamTerminalData?.city}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.epa == null ? "hidden" : "show"
                            }
                          >
                            <strong>EPA: </strong>
                            {TeamTerminalData?.epa}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.phone == null
                                ? "hidden"
                                : "show"
                            }
                          >
                            <strong>Phone Number: </strong>
                            {TeamTerminalData?.phone}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.stat == null ? "hidden" : "show"
                            }
                          >
                            <strong>Status: </strong>
                            {TeamTerminalData?.stat}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.state == null
                                ? "hidden"
                                : "show"
                            }
                          >
                            <strong>State: </strong>
                            {TeamTerminalData?.state}
                          </p>
                          <p
                            className={
                              TeamTerminalData?.country == null ? "hidden" : "show"
                            }
                          >
                            <strong>Country: </strong>
                            {TeamTerminalData?.country}
                          </p>{" "}
                          <p
                            className={
                              TeamTerminalData?.zip == null ? "hidden" : "show"
                            }
                          >
                            <strong>Zipcode: </strong>
                            {TeamTerminalData?.zip}
                          </p>{" "}
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{ textAlign: "left" }}
                            className="tabDetail-format"
                          >
                            <h3>Facility Information</h3>
                            {facilityData?.name == null &&
                            facilityData?.city == null &&
                            facilityData?.country == null &&
                            facilityData?.epa_id == null &&
                            facilityData?.generator_status == null &&
                            facilityData?.internal_id == null &&
                            facilityData?.phone_number == null &&
                            facilityData?.address == null &&
                            facilityData?.region == null &&
                            facilityData?.state == null &&
                            facilityData?.is_active == null &&
                            facilityData?.zip_code == null ? (
                              <p>No Data Available</p>
                            ) : (
                              <>
                                <p
                                  className={
                                    facilityData?.name == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Name: </strong>
                                  {facilityData?.name}
                                </p>
                                <p
                                  className={
                                    facilityData?.city == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility City: </strong>
                                  {facilityData?.city}
                                </p>
                                <p
                                  className={
                                    facilityData?.country == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Country: </strong>
                                  {facilityData?.country}
                                </p>
                                <p
                                  className={
                                    facilityData?.epa_id == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility EPA ID: </strong>
                                  {facilityData?.epa_id}
                                </p>
                                <p
                                  className={
                                    facilityData?.generator_status == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility General Status: </strong>
                                  {facilityData?.generator_status}
                                </p>
                                <p
                                  className={
                                    facilityData?.internal_id == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Internal ID: </strong>
                                  {facilityData?.internal_id}
                                </p>
                                <p
                                  className={
                                    facilityData?.phone_number == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Phone Number: </strong>
                                  {facilityData?.phone_number}
                                </p>
                                <p
                                  className={
                                    facilityData?.region == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Region: </strong>
                                  {facilityData?.region}
                                </p>
                                <p
                                  className={
                                    facilityData?.state == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility State: </strong>
                                  {facilityData?.state}
                                </p>
                                <p
                                  className={
                                    facilityData?.zip_code == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Facility Zip Code: </strong>
                                  {facilityData?.zip_code}
                                </p>
                                <p
                                  className={
                                    facilityData?.address == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Address: </strong>
                                  {facilityData?.address}
                                </p>
                                <p
                                  className={
                                    facilityData?.is_active == null
                                      ? "hidden"
                                      : "show"
                                  }
                                >
                                  <strong>Active: </strong>
                                  {facilityData?.is_active ? "Yes" : "No"}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </TabPanel>

                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {currentUser.data?.role.role === ROLES.PROB_PM
                      ? "Submit for Approval"
                      : "Update"}
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => closeEditSpill(jobNo)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </form>
            </div>
          </Paper>
        ) : (
          <div> The spill you are trying to view was not found </div>
        )}

        <Snackbar
          open={snackBarOpen}
          autoHideDuration={5000}
          onClose={handleSnackBarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleSnackBarClose} severity={snackBarSeverity}>
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </Fragment>

      <div>
        {
          <WholeScreenModal
            show={updateClientOrganization.isToBeUpdated}
            hide={() =>
              setUpdateClientOrganization({
                ...updateClientOrganization,
                isToBeUpdated: false,
              })
            }
          >
            <Prompt
              response={(response) => {
                setResponse(response)
                response
                  ? updateSpillHandler()
                  : setUpdateClientOrganization({
                    ...updateClientOrganization,
                    isToBeUpdated: false,
                  });
              }}
              headingText={clientOrgText.clientOrganizationUpdatePromptHeading}
              question={{
                question: clientOrgText.ClientOrganizationText,
                note: clientOrgText.ClientOrganizationTextNote,
              }}
              acceptText={clientOrgText.clientOrganizationUpdatePromptAcceptBtn}
              rejectText={clientOrgText.clientOrganizationUpdatePromptRejectBtn}
              isClintOrg={true}
              isSpillVoid={false}
            />
          </WholeScreenModal>
          
        }
        {
          <WholeScreenModal
            show={showMaterialClassEditPopup}
            hide={() => setShowMaterialClassEditPopup(false)}
            minWidth={800}
            minHeight={500}
            maxHeight={500}
          >
            <MaterialClassPopup
              response={(response) => setShowMaterialClassEditPopup(!response)}
              headingText={"Edit Material Class"}
              setSnackBarSeverity={setSnackBarSeverity}
              setSnackBarMessage={setSnackBarMessage}
              setSnackBarOpen={setSnackBarOpen}
              setMaterialClassValue={setMaterialClassValue}
              materialClassValue={materialClassValue}
            />
          </WholeScreenModal>
        }
      </div>

      <div>
        {
          <WholeScreenModal
            show={spillVoid.showVoidPrompt}
            hide={() => voidPromptHandler(false)}
          >
            <Prompt
              response={(response) => {
                response
                  ? clearSpillDataHandler()
                  : setSpillVoid({ showVoidPrompt: false, isVoid: false });
              }}
              headingText={voidText.voidSpillPromptHeading}
              question={{
                question: voidText.voidSpillPromptText,
                note: voidText.voidSpillPromptTextNote,
              }}
              acceptText={voidText.voidSpillPromptPromptAcceptBtn}
              rejectText={voidText.voidSpillPromptPromptRejectBtn}
              isClintOrg={false}
              isSpillVoid={true}
            />
          </WholeScreenModal>
        }
      </div>
    </>
  );
};

const mapStateToProps = ({
  user: { users, admins, currentUser, packetReviewers },
  client: { clientOrganizationNames, facilities, disableClientContractors, getIcsTerminal, getIcsTeamTerminal },
  spill: {
    currentSpill,
    currentSpillServices,
    material: { materials, materialsLoading },
    materialClass,
    status: { statuses, statusesLoading },
    success,
    loading,
    currentContractorHistory,
    spillAdminAttachmentHistory,
    paginatedNotes,
    adminAttachmentTypes,
    reminder,
  },
  agency: { agencies },
  contractor: { contractorsWithAddress },
  spill
}) => {
  return {
    users,
    admins,
    clientOrganizationNames,
    currentSpill,
    materials,
    materialClass,
    materialsLoading,
    agencies,
    currentUser,
    contractorsWithAddress,
    currentSpillServices,
    statuses,
    statusesLoading,
    success,
    loading,
    paginatedNotes,
    adminAttachmentTypes,
    packetReviewers,
    currentContractorHistory,
    spillAdminAttachmentHistory,
    facilities,
    disableClientContractors,
    partialBillingDownload:spill.partialBillingDownload
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAdmins: bindActionCreators(userActions.getAdmins, dispatch),
  editSpill: bindActionCreators(spillActions.editSpill, dispatch),
  getOrganizationNames: bindActionCreators(
    clientActions.getOrganizationNames,
    dispatch
  ),
  getCompleteSpill: bindActionCreators(spillActions.getCompleteSpill, dispatch),
  getAgencies: bindActionCreators(agencyActions.getAgencies, dispatch),
  getContractorsWithAddress: bindActionCreators(
    contractorAction.getContractorsWithAddress,
    dispatch
  ),
  getUsersByOrgId: bindActionCreators(userActions.getUsersByOrgId, dispatch),
  addAttachments: bindActionCreators(spillActions.addAttachments, dispatch),
  closeSpill: bindActionCreators(spillActions.closeSpill, dispatch),
  getPaginatedNotes: bindActionCreators(
    spillActions.getPaginatedNotes,
    dispatch
  ),
  getClientAssociatedNotesServices: bindActionCreators(
    spillActions.getClientAssociatedNotesServices,
    dispatch
    ),
  getMaterial: bindActionCreators(spillActions.getMaterial, dispatch),
  getMaterialClass: bindActionCreators(spillActions.getMaterialClass, dispatch),
  getMaterialClassReset: bindActionCreators(spillActions.getMaterialClassReset, dispatch),
  getStatuses: bindActionCreators(spillActions.getStatuses, dispatch),
  updateStatus: bindActionCreators(spillActions.updateStatus, dispatch),
  getContractorHistory: bindActionCreators(
    spillActions.getContractorHistory,
    dispatch
  ),
  getSpillAdminAttachmentHistory: bindActionCreators(
    spillActions.getSpillAdminAttachmentHistory,
    dispatch
  ),
  clearSpillData: bindActionCreators(spillActions.clearSpillData, dispatch),
  getSpillAdminAttachmentTypes: bindActionCreators(
    spillActions.getSpillAdminAttachmentTypes,
    dispatch
  ),
  fetchPacketReviewers: bindActionCreators(
    userActions.fetchPacketReviewers,
    dispatch
  ),
  getFacility: bindActionCreators(clientActions.getFacility, dispatch),
  getDisableContaractor: bindActionCreators(
    clientActions.getDisableContaractor,
    dispatch
  ),
  getIcsTerminal: bindActionCreators(clientActions.getIcsTerminal, dispatch),
  getIcsTeamTerminal: bindActionCreators(clientActions.getIcsTeamTerminal, dispatch),
  getPartialBillingDownload: bindActionCreators(spillActions.partialBillingDownload, dispatch),
  partialBillingDownloadReset: bindActionCreators(spillActions.partialBillingDownloadReset, dispatch),
  refreshMaterialClassReset: bindActionCreators(spillActions.refreshMaterialClassReset, dispatch),
  resetFetchCertainApiForEditSpill: bindActionCreators(spillActions.resetFetchCertainApiForEditSpill, dispatch),
});

EditSpill.propTypes = {
  users: PropTypes.object.isRequired,
  admins: PropTypes.object.isRequired,
  getAdmins: PropTypes.func.isRequired,
  getOrganizationNames: PropTypes.func.isRequired,
  clientOrganizationNames: PropTypes.object.isRequired,
  spillData: PropTypes.object.isRequired,
  currentSpill: PropTypes.object.isRequired,
  editSpill: PropTypes.func.isRequired,
  closeEditSpill: PropTypes.func.isRequired,
  getCompleteSpill: PropTypes.func.isRequired,
  agencies: PropTypes.func.isRequired,
  getAgencies: PropTypes.func.isRequired,
  getContractorsWithAddress: PropTypes.func.isRequired,
  contractorsWithAddress: PropTypes.func.isRequired,
  getUsersByOrgId: PropTypes.func.isRequired,
  addAttachments: PropTypes.func.isRequired,
  closeSpill: PropTypes.func.isRequired,
  getClientAssociatedNotesServices: PropTypes.func.isRequired,
  paginatedNotes: PropTypes.object.isRequired,
  adminAttachmentTypes: PropTypes.object.isRequired,
  getMaterial: PropTypes.func.isRequired,
  getMaterialClass: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  success: PropTypes.bool.isRequired,
  packetReviewers: PropTypes.object.isRequired,
  getDisableContaractor: PropTypes.object.isRequired,
  getIcsTerminal: PropTypes.object.isRequired,
  getIcsTeamTerminal: PropTypes.object.isRequired,
  partialBillingDownload:PropTypes.object.isRequired,
  getPartialBillingDownload: PropTypes.func.isRequired,
  partialBillingDownloadReset:PropTypes.func.isRequired,
  getMaterialClassReset: PropTypes.func.isRequired,
  refreshMaterialClassReset: PropTypes.func.isRequired,
  resetFetchCertainApiForEditSpill: PropTypes.func.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditSpill)
);